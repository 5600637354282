<template>
  <div class="box-proposal mb-5">
    <div v-if="status == 'APROVADA'" class="box-message-approve">
      <div class="box-message-icon">&#10004;</div>
      <div class="">
        Proposta de Curto Prazo <b>APROVADA</b> por {{ APROVADO_POR }} (e-mail:
        {{ EMAIL_APROVADOR }}).
      </div>
    </div>

    <div style="background: #fff">
      <div class="proposal-header">
        Proposta comercial de COMPRA de energia elétrica (Curto Prazo).
      </div>
      <div style="margin-top: 20px" class="proposal-content">
        <div class="proposal-content-item">
          <div class="content-item-title">Vendedor</div>
          <div class="content-item-value">
            MATRIX COMERCIALIZADORA DE ENERGIA ELETRICA S.A.
          </div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Comprador</div>
          <div class="content-item-value">{{ COMPRADOR }}</div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">CNPJ do Comprador</div>
          <div class="content-item-value">
            {{ CNPJ | cnpj }}
          </div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Fonte</div>
          <div class="content-item-value">
            {{ TIPO_ENERGIA }}
          </div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Submercado</div>
          <div class="content-item-value">{{ SUBMERCADO }}</div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Período de Fornecimento</div>
          <div class="content-item-value">{{ PERIODO }}</div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Volume Total (montante)</div>
          <div class="content-item-value">
            <b>{{ VOLUME_TOTAL | volume }} MWh</b>
          </div>
        </div>
        <div style="border: none" class="proposal-content-item">
          <div class="content-item-title">Volume(s) Distribuído(s)</div>
          <div class="content-item-value"></div>
        </div>
      </div>
      <div class="proposal-content">
        <div class="grid-scroll">
          <div class="grid-distribution">
            <div class="grid-header">
              <div style="width: 20%" class="">CNPJ</div>
              <div style="width: 60%" class="">Razão Social</div>
              <div style="width: 20%" class="">Vol. Distribuído (MWh)</div>
            </div>
            <div style="border-bottom: 1px solid #929292">
              <div
                v-for="(contrato, indexContraxt) in VOLUME_DISTRIBUIDO"
                :key="`indexContraxt${indexContraxt}`"
                class="grid-body"
              >
                <div style="width: 20%" class="">
                  {{ contrato.cnpj | cnpj }}
                </div>
                <div style="width: 60%" class="">
                  {{ contrato.razaoSocial }}
                </div>
                <div style="width: 20%" class="">
                  <b>{{ contrato.volumeDistribuido | volume }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 20px" class="proposal-content pb-3">
        <div class="proposal-content-item">
          <div class="content-item-title">Flexibilidade</div>
          <div class="content-item-value">{{ FLEXIBILIDADE }}</div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Preço</div>

          <div class="content-item-value">
            {{ PRECO }}
          </div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Reajuste de Preço</div>
          <div class="content-item-value">NÃO SE APLICA</div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Tributos</div>
          <div class="content-item-value">
            NO PREÇO ESTÃO INCLUSOS PIS/COFINS, EXCETO ICMS
          </div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Condições de Pagamento</div>
          <div class="content-item-value">
            {{ PARAMETRIZACAO.condicaoPagamento }}
          </div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Registro CliqCCEE</div>
          <div class="content-item-value">
            REGISTRO NA CCEE CONTRA PAGAMENTO
          </div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Garantia</div>
          <div class="content-item-value">NÃO POSSUI</div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Multa e Juros</div>
          <div class="content-item-value">
            {{ PARAMETRIZACAO.multaJuros }}
          </div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Reembolso TUSD (TRU)</div>
          <div class="content-item-value">
            {{ TUSD }}
          </div>
        </div>
        <div class="proposal-content-item">
          <div class="content-item-title">Validade da Proposta</div>
          <div class="content-item-value">{{ VALIDADE }}</div>
        </div>
        <div v-if="status == 'EM_PREENCHIMENTO'" class="box-buttons">
          <button
            @click="$parent.voltar"
            class="btn-action-secondary-newave"
            style="padding: 8px; max-width: 300px"
          >
            VOLTAR
          </button>

          <button
            class="btn-action-primary-newave ml-2"
            @click="$parent.iniciarAprovacao"
            style="padding: 8px; max-width: 300px; margin-left: 20px"
          >
            APROVAR PROPOSTA
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon";
import { formatData } from "@/utils/functionsUtils";

export default {
  props: {
    status: String,
    proposta: Object,
  },
  data() {
    return {
      proposalShow: true,
      proposal: {
        satatus: "",
      },
    };
  },
  computed: {
    APROVADO_POR() {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.proposta &&
        this.proposta.curtoPrazo.proposta.nomeAprovador
        ? this.proposta.curtoPrazo.proposta.nomeAprovador
        : "--";
    },
    EMAIL_APROVADOR() {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.proposta &&
        this.proposta.curtoPrazo.proposta.emailAprovador
        ? this.proposta.curtoPrazo.proposta.emailAprovador
        : "--";
    },
    COMPRADOR() {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.proposta &&
        this.proposta.curtoPrazo.proposta.contrato &&
        this.proposta.curtoPrazo.proposta.contrato.empresa
        ? this.proposta.curtoPrazo.proposta.contrato.empresa.razaoSocial
        : "--";
    },
    CNPJ() {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.proposta &&
        this.proposta.curtoPrazo.proposta.contrato &&
        this.proposta.curtoPrazo.proposta.contrato.empresa
        ? this.proposta.curtoPrazo.proposta.contrato.empresa.cnpj
        : "--";
    },
    TIPO_ENERGIA() {
      return this.proposta &&
        this.proposta.curtoPrazo.proposta &&
        this.proposta.curtoPrazo.proposta.contrato
        ? this.proposta.curtoPrazo.proposta.contrato.tipoEnergia
        : "--";
    },
    SUBMERCADO() {
      return this.proposta &&
        this.proposta.curtoPrazo.proposta &&
        this.proposta.curtoPrazo.proposta.contrato
        ? this.proposta.curtoPrazo.proposta.contrato.submercado
        : "--";
    },
    PERIODO() {
      return this.proposta &&
        this.proposta.curtoPrazo.proposta &&
        this.proposta.curtoPrazo.proposta.contrato
        ? this.proposta.curtoPrazo.proposta.contrato.dataReferencia
        : "--";
    },
    VOLUME_TOTAL() {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.proposta
        ? this.proposta.curtoPrazo.proposta.volumeTotal
        : "--";
    },
    PRECO() {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.proposta
        ? this.proposta.curtoPrazo.proposta.preco
        : "--";
    },
    PARAMETRIZACAO() {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.proposta &&
        this.proposta.curtoPrazo.proposta.curtoPrazoParametrizacao
        ? this.proposta.curtoPrazo.proposta.curtoPrazoParametrizacao
        : "--";
    },
    VALIDADE() {
      const hour =
        DateTime.fromISO(this.proposta.curtoPrazo.proposta.dataLimite).toFormat(
          "HH"
        ) - 3;
      const minutes = DateTime.fromISO(
        this.proposta.curtoPrazo.proposta.dataLimite
      ).toFormat("mm");
      const date = DateTime.fromISO(
        this.proposta.curtoPrazo.proposta.dataLimite
      ).toFormat("dd/MM/yyyy");
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.proposta
        ? `${date} ${hour}:${minutes}`
        : "--";
    },
    TUSD() {
      return this.proposta &&
        this.proposta.curtoPrazo.proposta &&
        this.proposta.curtoPrazo.proposta.curtoPrazoTusd
        ? this.proposta.curtoPrazo.proposta.curtoPrazoTusd.descricao
        : "--";
    },
    FLEXIBILIDADE() {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.proposta.contrato &&
        this.proposta.curtoPrazo.proposta.contrato
        ? this.proposta.curtoPrazo.proposta.contrato.tipo
        : "--";
    },
    VOLUME_DISTRIBUIDO() {
      return this.proposta &&
        this.proposta.curtoPrazo &&
        this.proposta.curtoPrazo.proposta
        ? this.proposta.curtoPrazo.proposta.volumesDistribuidos
        : [];
    },
  },
};
</script>
<style lang="scss" scoped>
.templates {
  padding-top: 50px;
}
.page {
  background: #e5e5e5;
  min-height: 117.6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.__header {
  position: fixed;
  height: 50px;
  width: 100%;
  display: flex;
  background: #505050;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #ffffff;
  img {
    width: 65px;
  }
}
.header__box-logo {
  padding: 0 15px 0 15px;
}
.box-specification,
.box-distribution,
.box-proposal {
  background: #fff;
  width: 80%;
  margin-top: 30px;
  border-radius: 5px;
}
.box-specification,
.box-proposal {
  margin-top: 80px;
}
.box-proposal {
  background: none;
}
.box-distribution {
  padding: 20px;
}
.specification-header,
.proposal-header {
  display: flex;
  align-items: center;
  padding: 6px 0px 6px 25px;
  background:#0B2B40;
  border-radius: 4px 4px 0px 0px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #ffffff;
  //@include mobile {
  // font-size: 14px;
  //}
}
.specification-content {
  padding: 20px;
}
.box-specification-infos,
.box-distribution-infos {
  background: #f8f8f8;
  border-radius: 5px;
  width: 100%;
  padding: 11px 30px 11px 30px;
  display: flex;
  justify-content: space-between;
  //@include mobile {
  // flex-direction: column;
  //  text-align: center;
  // }
}
.infos-item {
  margin-bottom: 10px;
}
.option-item {
  display: flex;
  padding: 10px;
  text-align: left;
}
.box-input-radio {
  padding: 0px 10px 0px 10px;
}
.box-distribution {
  padding-top: 20px;
}
.specification-label {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #000000;
}
.box-specificstion-option {
  display: flex;
  justify-content: space-between;
  //@include mobile {
  //  flex-direction: column;
  //  text-align: center;
  //}
}

.option-left {
  width: 40%;
  //@include mobile {
  width: 100%;
}
.option-right {
  width: 60%;
  //@include mobile {
  //  width: 100%;
  //}
}

.distribution-header {
  margin: 30px 0 25px 0;
  display: flex;
  justify-content: space-between;
  //include mobile {
  //  flex-direction: column;
  //  text-align: center;
  //}
}
.grid-scroll {
  overflow: auto;
}
.grid-distribution {
  min-width: 700px;
}
.grid-header,
.grid-body {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 8px 0 8px 0;
}
.grid-header {
  font-weight: 600;
  color: #000000;
  border-bottom: 1px solid #929292;
}
.grid-body {
  color: #505050;
  border-bottom: 1px solid #e5e5e5;
}
.box-grid-total {
  background: #e8e8e8;
  display: flex;
  justify-content: flex-end;
  padding: 8px 0 8px 0;
}
.info-value {
  font-weight: bold;
}
.numeric-field {
}
.numeric-field-grid {
  background: #f1f1f1;
  border-radius: 3px;
  border: none;
  width: 100px;
  text-align: center;
  &:disabled {
    background: #c4c4c4;
    color: #c4c4c4;
  }
}
.color-red {
  color: red;
}
.box-buttons {
  margin-top: 31px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.btn-orange {
  width: 174px;
  height: 42px;
  background: #0B2B40;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background: rgba($color: #0B2B40, $alpha: 0.8);
    color: #fff;
  }
  &:disabled {
    background: rgba($color: #ccc, $alpha: 0.8);
    cursor: not-allowed;
  }
}
.btn-border {
  width: 174px;
  height: 42px;
  border: 1px solid #505050;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
}

.proposal-content {
  padding: 0 20px 0 20px;
}

.proposal-content-item {
  display: flex;
  padding: 8px 0 8px 0;
  border-bottom: 1px solid #e5e5e5;
  //@include tablet {
  // flex-direction: column;
  //}
}
.content-item-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000;
  min-width: 250px;
}
.content-item-value {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #505050;
}
.box-message-approve {
  padding: 10px 0 10px 0px;
  background: #ffffff;
  border: 1px solid #2ed47a;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.box-message-icon {
  color: #2ed47a;
  font-size: 20px;
  min-width: 60px;
  text-align: center;
}
.alert-msg {
  width: 100%;
  background: #f8f8f8;
  border-radius: 5px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #505050;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.alert-icon {
  padding: 0 10px 0 10px;
  img {
    width: 20px;
    margin-top: -2px;
  }
}
.box-content {
  margin-top: 100px;
}
.content-icon {
  padding: 36px 20px 20px 20px;
  //@include mobile {
  //  flex-direction: column;
  //  padding: 20px;
  // }
}
</style>