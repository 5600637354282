export default class FaturamentoModel {
  constructor(
    {
      id,
      codigo,
      codigoContratoReferencia,
      dataReferencia,
      rateio,
      descricaoPortifolio,
      tipo,
      take,
      parametrizacao,
      flexibilidade,
      percentualPerda,
      percentualFatorAtendimento,
      proinfa,
      empresa,
      statusAtual,
      pontoMedicao,
      calculoResultado,
      links,
      calculoFaturamento,
      responsavel,
      warnings,
      descricaoEnergia,
      perfilContratante,
      siglaCceeContratante,
      grupoEconomico,
    } = new Object()
  ) {
    this._id = id;
    this._codigo = codigo;
    this._codigoContratoReferencia = codigoContratoReferencia;
    this._dataReferencia = dataReferencia;
    this._rateio = rateio;
    this._descricaoPortifolio = descricaoPortifolio;
    this._tipo = tipo;
    this._take = take;
    this._parametrizacao = parametrizacao;
    this._flexibilidade = flexibilidade;
    this._percentualPerda = percentualPerda;
    this._percentualFatorAtendimento = percentualFatorAtendimento;
    this._proinfa = proinfa;
    this._empresa = empresa;
    this._statusAtual = statusAtual;
    this._pontoMedicao = pontoMedicao;
    this._calculoResultado = calculoResultado;
    this._links = links;
    this._calculoFaturamento = calculoFaturamento;
    this._responsavel = responsavel;
    this._warnings = warnings;
    this._descricaoEnergia = descricaoEnergia;
    this._perfilContratante = perfilContratante;
    this._siglaCceeContratante = siglaCceeContratante;
    this._grupoEconomico = grupoEconomico;
  }

  get id() {
    return this._id ?? null;
  }

  set id(id = null) {
    this._id = id;
  }

  get codigo() {
    return this._codigo ?? null;
  }

  set codigo(codigo = null) {
    this._codigo = codigo;
  }

  get codigoContratoReferencia() {
    return this._codigoContratoReferencia ?? null;
  }

  set codigoContratoReferencia(codigoContratoReferencia = null) {
    this._codigoContratoReferencia = codigoContratoReferencia;
  }

  get dataReferencia() {
    return this._dataReferencia ?? null;
  }

  set dataReferencia(dataReferencia = null) {
    this._dataReferencia = dataReferencia;
  }

  get rateio() {
    return this._rateio ?? null;
  }

  set rateio(rateio = null) {
    this._rateio = rateio;
  }

  get descricaoPortifolio() {
    return this._descricaoPortifolio ?? null;
  }

  set descricaoPortifolio(descricaoPortifolio = null) {
    this._descricaoPortifolio = descricaoPortifolio;
  }

  get tipo() {
    return this._tipo ?? null;
  }

  set tipo(tipo = null) {
    this._tipo = tipo;
  }

  get take() {
    return this._take ?? null;
  }

  set take(take = null) {
    this._take = take;
  }

  get parametrizacao() {
    return (
      this._parametrizacaos ?? {
        envioAutomatico: false,
      }
    );
  }

  set parametrizacao(parametrizacaos = null) {
    this._parametrizacaos = parametrizacaos;
  }

  get flexibilidade() {
    return (
      this._flexibilidade ?? {
        quantidadeContratada: 0,
        limiteMinimo: 0,
        limiteMaximo: 0,
      }
    );
  }

  set flexibilidade(flexibilidade = null) {
    this._flexibilidade = flexibilidade;
  }

  get percentualPerda() {
    return this._percentualPerda ?? null;
  }

  set percentualPerda(percentualPerda = null) {
    this._percentualPerda = percentualPerda;
  }

  get percentualFatorAtendimento() {
    return this._percentualFatorAtendimento ?? null;
  }

  set percentualFatorAtendimento(percentualFatorAtendimento = null) {
    this._percentualFatorAtendimento = percentualFatorAtendimento;
  }

  get proinfa() {
    return this._proinfa ?? 0;
  }

  set proinfa(proinfa = null) {
    this._proinfa = proinfa;
  }

  get empresa() {
    return (
      this._empresa ?? {
        id: null,
        cnpj: "--",
        razaoSocial: "--",
        apelido: "--",
        posVenda: "--",
      }
    );
  }

  set empresa(empresa = null) {
    this._empresa = empresa;
  }

  get statusAtual() {
    return (
      this._statusAtual ?? {
        id: null,
        descricao: null,
      }
    );
  }

  set statusAtual(statusAtual = null) {
    this._statusAtual = statusAtual;
  }

  get pontoMedicao() {
    return (
      this._pontoMedicao ?? {
        codigo: "--",
        codigoRetaguarda: null,
        id: null,
      }
    );
  }

  set pontoMedicao(pontoMedicao = null) {
    this._pontoMedicao = pontoMedicao;
  }

  get calculoResultado() {
    return (
      this._calculoResultado ?? {
        montanteSCDE: null,
        solicitadoBruto: null,
        solicitadoLiquido: null,
      }
    );
  }

  set calculoResultado(calculoResultado = null) {
    this._calculoResultado = calculoResultado;
  }

  get links() {
    return this._links ?? [];
  }

  set links(links = null) {
    this._links = links;
  }

  get calculoFaturamento() {
    return (
      this._calculoFaturamento ?? {
        id: null,
      }
    );
  }

  set calculoFaturamento(calculoFaturamento = null) {
    this._calculoFaturamento = calculoFaturamento;
  }

  get responsavel() {
    return this._responsavel ?? null;
  }

  set responsavel(responsavel = null) {
    this._responsavel = responsavel;
  }

  get warnings() {
    return this._warnings ?? [];
  }

  set warnings(warnings = null) {
    this._warnings = warnings;
  }

  get descricaoEnergia() {
    return this._descricaoEnergia ?? null;
  }

  set descricaoEnergia(descricaoEnergia = null) {
    this._descricaoEnergia = descricaoEnergia;
  }

  get perfilContratante() {
    return (
      this._perfilContratante ?? {
        id: null,
        codigo: null,
        descricao: null,
        descontoBoleta: null,
        consumo: null,
        sigla: null,
        incentivada: null,
        dataUltimaAtualizacao: null,
      }
    );
  }

  set perfilContratante(perfilContratante = null) {
    this._perfilContratante = perfilContratante;
  }

  get siglaCceeContratante() {
    return (
      this._siglaCceeContratante ?? {
        id: null,
        codigo: null,
        descricao: null,
        codigoPerfilCceeId: null,
        numeroCcee: null,
        codigoEmpresaId: null,
        descricaoMceif: null,
        padrao: null,
        dataUltimaAtualizacao: null,
      }
    );
  }

  set siglaCceeContratante(siglaCceeContratante = null) {
    this._siglaCceeContratante = siglaCceeContratante;
  }

  get grupoEconomico() {
    return this._grupoEconomico === "NEWAVE_BISMUT" ? "Newave" : "Matrix";
  }

  set grupoEconomico(grupoEconomico = null) {
    this._grupoEconomico = grupoEconomico;
  }

  getData() {
    const {
      id,
      codigo,
      codigoContratoReferencia,
      dataReferencia,
      rateio,
      descricaoPortifolio,
      tipo,
      take,
      parametrizacao,
      flexibilidade,
      flexibilidade_quantidadeContratada,
      flexibilidade_limiteMinimo,
      flexibilidade_limiteMaximo,
      percentualPerda,
      percentualFatorAtendimento,
      proinfa,
      empresa,
      statusAtual,
      pontoMedicao,
      calculoResultado,
      links,
      calculoFaturamento,
      responsavel,
      warnings,
      descricaoEnergia,
      perfilContratante,
      siglaCceeContratante,
      grupoEconomico,
    } = this;
    return {
      id,
      codigo,
      codigoContratoReferencia,
      dataReferencia,
      rateio,
      descricaoPortifolio,
      tipo,
      take,
      parametrizacao,
      flexibilidade,
      percentualPerda,
      percentualFatorAtendimento,
      proinfa,
      empresa,
      statusAtual,
      pontoMedicao: pontoMedicao ? pontoMedicao.codigo : "--",
      calculoResultado,
      links,
      calculoFaturamento,
      responsavel,
      warnings,
      descricaoEnergia,
      perfilContratante,
      siglaCceeContratante,
      grupoEconomico,
    };
  }
}
