<template>
  <div style="display: flex; height: 20px;">
    <span class="timer"
    ><Icon
      name="clock"
      :color="`${color}`"
    /></span>
    <b
    ><span :style="`color: ${color};`"> 00:{{ minutos }}:{{ segundos }}</span></b
    >
  </div>
</template>
<script>
import Icon from "@/pages/hot-site/components/MutableIcons.vue";

export default {
  props: {
    ["periodo"]: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "#f86511",
    },
  },
  components: {
    Icon,
  },
  data() {
    return {
      horas: "00",
      minutos: "00",
      segundos: "00",
      timer: 0,
    };
  },
  mounted() {
    this.timer = this.periodo;
    this.start();
  },
  methods: {
    start() {
      setInterval(() => {
        this.minutos = parseInt(this.timer / 60, 10);
        this.segundos = parseInt(this.timer % 60, 10);
        this.minutos = this.minutos < 10 ? "0" + this.minutos : this.minutos;
        this.segundos =
          this.segundos < 10 ? "0" + this.segundos : this.segundos;
        if (--this.timer < 0) {
          this.timer = this.timer - 60;
          this.timer = 0;
          this.$emit("finished");
        } else {
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.timer {
  width: 2rem;
}
</style>
