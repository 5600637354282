<template>
  <div>
    <modal-base
      max-width="800px"
      :iconClose="false"
      data-cy="modal-fechar_modal_aprovacao-modais"
      v-if="showModal"
    >
      <template #header>
        APROVAR DEMONSTRATIVO DE FATURAMENTO
      </template>
      <template #body>
        <div class="body-container">
          <div class="wrapper">
            <div class="attention">ATENÇÃO</div>
            <div class="messages">
              <div style="margin-bottom: 27px">
                <b>Confirmação Enviada!</b>
              </div>
              <div style="margin-bottom: 34px">
                Sua Nota Fiscal será processada e enviada em breve.
              </div>
              <div style="margin-bottom: 34px">
                Deseja receber a <b>Proposta de Curto Prazo?</b>
              </div>
            </div>

          </div>
        </div>
      </template>
      <template #footer>
        <div class="box-button">
          <button
            @click="cancel"
            class="btn btn-action-primary-outlined mr-2"
          >NÃO, OBRIGADO</button>
          <button
            @click="ok"
            class="btn  btn-action-primary ml-2"
          >
            SIM, QUERO RECEBER PROPOSTA
          </button>
        </div>
      </template>
    </modal-base>

  </div>
</template>
<script>
import ModalBase from "@/components/modals/ModalBase.vue";
export default {
  components: {
    ModalBase,
  },
  props: {
    showModalSolicitarCurtoPrazo: Boolean,
    default: false,
  },
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    this.showModal = this.showModalSolicitarCurtoPrazo;
  },

  watch: {
    showModalSolicitarCurtoPrazo: function () {
      this.showModal = this.showModalSolicitarCurtoPrazo;
    },
  },

  methods: {
    cancel() {
      this.$emit("salvarSemCurtoPrazo");
      this.showModal = false
    },
    ok() {
      this.$emit("salvarComCurtoPrazo");
      this.showModal = false
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 15px;
  position: relative;
}
.attention{
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #707683;
  background: #e8e8e8;
  border-radius: 5px;
  padding: 6px;
  text-align: center;
}
.messages {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 20px 0px 33px 0px;
  text-align: center;
}
.box-button {
  width: 100%;
  text-align: center;
}
.btn {
  max-width: 350px;
  margin: 8px 10px;
}
</style>