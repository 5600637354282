<template>
  <div>
    <div class="card-chart-history">
      <div :class="titleStyle">
        Histórico do Consumo Apurado (Últimos 3 meses)
      </div>
      <div class="box-chart-history">
        <canvas style="width: 100%" id="chartHistory" />
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    historicoConsumo: Array,
    titleStyle: {
      type: String,
      default: "_card_title",
    },
    color: {
      type: String,
      default: "#f86511",
    },
  },
  mounted() {
    var ctx = document.getElementById("chartHistory").getContext("2d");
    this.chart_history = new Chart(ctx, this.chart_history_config);

    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (
          chart.config.options.chartArea &&
          chart.config.options.chartArea.backgroundColor
        ) {
          var ctx = chart.chart.ctx;
          var chartArea = chart.chartArea;

          ctx.save();
          ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
          ctx.fillRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top
          );
          ctx.restore();
        }
      },
    });
  },
  data() {
    return {
      chart_history: null,
      chart_history_config: {
        type: "line",
        data: {
          labels: [
            ...this.historicoConsumo.map((g) => g.dataReferenciaFormatada),
          ],
          datasets: [
            {
              spanGaps: false,
              color: "#f00",
              backgroundColor: "rgba(255,255,255,0",
              lineTension: 0,
              borderColor:  this.color,
              pointBackgroundColor:  this.color,
              data: [...this.historicoConsumo.map((g) => g.consumoApurado)],
              pointRadius: 4,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          layout: {
            padding: {
              left: 30,
              right: 30,
              top: 30,
              bottom: 30,
            },
          },
          chartArea: {
            backgroundColor: "rgba(230, 230, 230, 0.6)",
            border: "2px solid #0000ff",
          },
          plugins: {
            beforeDraw: function (chart) {
              if (
                chart.config.options.chartArea &&
                chart.config.options.chartArea.backgroundColor
              ) {
                var ctx = chart.chart.ctx;
                var chartArea = chart.chartArea;

                ctx.save();
                ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
                ctx.fillRect(
                  chartArea.left,
                  chartArea.top,
                  chartArea.right - chartArea.left,
                  chartArea.bottom - chartArea.top
                );
                ctx.restore();
              }
            },
            datalabels: {
              color: "#000000",

              align: "top",
              font: {
                size: "15",
                weight: "bold",
              },
              rotation: function () {
                if (window.innerWidth < 600) {
                  return -90;
                }
                return 0;
              },
              formatter: function (value) {
                if (!value || isNaN(value) || !isFinite(value)) {
                  return "";
                }
                var m = parseFloat(value).toFixed(3);
                m = m.toString().replace(".", ",");
                var parts = m.split(",");
                if (parts[0].length > 3) {
                  parts[0] = [
                    parts[0].slice(0, -3),
                    ".",
                    parts[0].slice(-3),
                  ].join("");
                }
                return parts[0] + "," + parts[1];
              },
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  autoSkip: true,
                  maxTicksLimit: 5,
                  z: 5,
                },
                afterTickToLabelConversion: function (v) {
                  for (var tick in v.ticks) {
                    var m = parseFloat(v.ticks[tick]).toFixed(0);
                    m = m.toString().replace(".", ",");
                    if (m.length > 3) {
                      m = [m.slice(0, -3), ".", m.slice(-3)].join("");
                    }
                    v.ticks[tick] = m;
                  }
                },
                gridLines: {
                  borderDash: [5],
                  display: false,
                  lineWidth: 0,
                  drawBorder: true,
                },
              },
            ],
            xAxes: [
              {
                showLine: false,
                xOffset: "50",
                offset: true,
                type: "category",
                ticks: {
                  beginAtZero: false,
                },
                gridLines: {
                  backgroundColor: "#ff0",
                  display: true,
                  lineWidth: 0,
                  // zeroLineColor: "#929292",
                  drawBorder: true,
                  showLine: false,
                },
              },
            ],
          },
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.card-chart-history {
  width: 100%;
  margin-top: 25px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #ffffff;
}
.box-chart-history {
  max-height: 220px;
  min-height: 220px;
}
._card_title {
  border-bottom: solid 1px #ebeff2;
  padding: 10px;
  padding-left: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #192a3e;
}

.dark-title {
  padding: 2% 1%;
  font-weight: 700;
  color: #ffffff;
  font-size: 1.5rem;
  background-color: #2a2a2a;
  border-radius: 0.3em 0.3em 0 0;
  border-bottom: 1px solid #f86511;
}
</style>