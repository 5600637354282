<template>
  <div>
    <div class="box-terms">
      <div class="_card_title">Definição de Termos</div>
      <div class="terms-line">
        <div class="terms-item">
          <div class="term-title">Contratado</div>
          <div class="term-text">
            Montante de energia elétrica contratado sazonalizado para o mês de
            suprimento.
          </div>
        </div>
        <div class="terms-item">
          <div class="term-title">Montante SCDE</div>
          <div class="term-text">
            Montante de energia elétrica total consumida no mês de suprimento,
            conforme apurado pelo SCDE CCEE.
          </div>
        </div>
        <div class="terms-item">
          <div class="term-title">Consumo Apurado</div>
          <div class="term-text">
            Montante de energia após aplicação dos parâmetros conforme fórmula
            abaixo.
          </div>
        </div>
      </div>
      <div class="term-tarja">
        Consumo Apurado = ((Montante SCDE * (1 + perdas)) - PROINFA) X Fator de
        Atendimento
      </div>
      <div
        v-if="contrato && contrato.classificacao !== 'FULL FLEX'"
        class="terms-line"
      >
        <div class="terms-item">
          <div class="term-title">Take Mínimo</div>
          <div class="term-text">Limite mínimo do volume contratado.</div>
        </div>
        <div class="terms-item">
          <div class="term-title">Take Máximo</div>
          <div class="term-text">Limite máximo do volume contratado.</div>
        </div>
        <div class="terms-item">
          <div class="term-title">PLD</div>
          <div class="term-text">Preço médio mensal calculado pela CCEE.</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["contrato"],
};
</script>
<style lang="scss" scoped>
.title {
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 0.05em;
  color: #505050;
}
.subtitle {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.01em;
  color: #505050;
}
.box-cards {
  margin-top: 10px;
}
.cards-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
._card {
  background: #ffffff;
  height: 150px;
  width: calc(50% - 13px);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.box-take {
  width: 100%;
  height: 140px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #ffffff;
}

.card-chart-history {
  width: 100%;
  margin-top: 25px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #ffffff;
}
.box-chart-history {
  max-height: 220px;
  min-height: 220px;
}

.box-units {
  width: 100%;
  margin-top: 25px;
  min-height: 150px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #ffffff;
}

.box-terms {
  width: 100%;
  margin-top: 25px;
  min-height: 150px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #ffffff;
}

._card_title {
  border-bottom: solid 1px #ebeff2;
  padding: 10px;
  padding-left: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #192a3e;
}
._card_body {
  display: flex;
  justify-content: space-between;
  height: 107px;
  align-items: center;
}
._card_logo {
  padding: 0 20px 0 20px;
}
._card_value {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #f86511;
}
.wrapper-takes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 98px;
  color: #f86511;
}
.box-take-min {
  width: 50%;
  display: flex;
  border-right: dashed 1px #eee;
  height: 100%;
  align-items: center;
  color: #f86511;
}
.box-take-max {
  width: 50%;
  display: flex;
  height: 100%;
  align-items: center;
}
.take-description {
  padding-left: 15px;
  width: 50%;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #929292;
}
.take-value {
  width: 50%;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #f86511;
}
.terms-line {
  display: flex;
  min-height: 100px;
}
.terms-item {
  display: flex;
  width: 33.33%;
}
.term-title {
  font-weight: bold;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #929292;
  display: flex;
  text-align: center;
  align-items: center;
  width: 30%;
  justify-content: center;
}
.term-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #929292;
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: center;
  padding: 10px;
}

.term-tarja {
  background: #2a2a2a;
  color: white;
  text-align: center;
  padding: 10px;
  font-size: 14px;
}

.unit-scroll {
  overflow: auto;
}

.table-unit {
  min-width: 1100px;
  width: 100%;
  border-spacing: 0;
  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #334d6e;
    opacity: 0.5;
  }
  td {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #707683;
    text-align: center;
    border-bottom: 1px dashed #bbb;
  }
}

.rows-table {
  min-height: 40px !important;
  td {
    height: 40px !important;
  }
}

.cnpj {
  min-width: 140px !important;
}
body {
  font-family: "Roboto", sans-serif;
}
</style>