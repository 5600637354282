<template>
  <div>
    <div class="box-units">
      <div class="units-header">
        <div class="_box_title">{{ title }}</div>
      </div>

      <div class="unit-scroll">
      <table class="card-table">
        <tbody v-if="!!config">
          <tr
            :key="`key_${i}`"
            v-for="(key, i) in config"
            :class="{ stripes: stripes }"
            :style="key.color ? `border: 2px solid ${key.color};` : ''"
          >
            <td>
              <b>{{ key.info }}</b> <span> {{ key.desc }} </span>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fs: String,
    config: Array,
    width: String,
    stripes: Boolean,
    titleColor: String,
    title: {
      type: String,
      default: "Default Title",
    },
  },
};
</script>
<style lang="scss" scoped>
.box-units {
  width: 100%;
  margin-top: 25px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #ffffff;
}
.units-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
}
._box_title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #192a3e;
}
.unit-scroll {
  overflow: auto;
  font-size: 14px;
}
.table-unit {
  min-width: 1100px;
  width: 100%;
  border-spacing: 0;
  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000;
    border-bottom: 1px solid #000;
    padding: 5px 0 5px 0;
  }
  td {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #505050;
    text-align: center;
    border-bottom: 1px solid #bbb;
  }
}
.rows-table {
  min-height: 40px !important;
  td {
    height: 40px !important;
  }
}
.cnpj {
  min-width: 140px !important;
}
.rows-total {
  border: none !important;
  background: #e8e8e8;
  td {
    border: none !important;
    font-weight: 600;
  }
}
.card-table {
  width: 100%;
  margin-top: -10px;
  border-radius: 0.3em;
  background-color: #ffffff;

  tr {
    width: 100%;

    td {
      padding: 1% 2% 1% 2%;
    }
  }
}
</style>