<template>
  <div class="wrapper-situacao">
    <OverlayLoader v-if="loading" />
    <div class="sitacao-body">
      <FormUser
        ref="user"
        @next="next"
        v-if="showFormGetUser"
        title="ATENÇÃO"
        :message="MESSAGE"
        @getInfosUser="getInfosUser"
      >
      </FormUser>
      <form-justificativa
        ref="justificativa"
        @finalizar="finalizar"
        v-if="showFormGetJustificativa"
        @getJustificativa="getJustificativa"
      />
    </div>
    <div class="situacao-footer">
      <button @click="send" :class="buttonStyle">Avançar</button>
    </div>

  </div>
</template>
<script>
import FormUser from "@/pages/hot-site/components/FormUser.vue";
import FormJustificativa from "@/pages/hot-site/components/FormJustificativa";
import DemonstrativoService from "@/services/DemonstrativoService";
import OverlayLoader from "@/components/OverlayLoader";
export default {
  components: {
    OverlayLoader,
    FormJustificativa,
    FormUser,
  },
  props: {
    contrato: Object,
    situacao: String,
    buttonStyle: {
      type: String,
      default: "btn-action-primary",
    },
  },

  data() {
    return {
      payload: {
        contratoId: null,
        nome: "",
        email: "",
        justificativa: "",
        situacao: "",
      },
      userInfosIsValid: false,

      showFormGetUser: true,
      showFormGetJustificativa: false,

      loading: false,
    };
  },

  mounted() {
    this.payload.situacao = this.situacao;
    this.payload.contratoId = this.contrato.id;
  },

  methods: {
    getInfosUser(infos) {
      this.payload.nome = infos.nome;
      this.payload.email = infos.email;
      this.userInfosIsValid = infos.isValid;
      this.payload.situacao = this.situacao;
    },
    getJustificativa(justificativa) {
      this.payload.justificativa = justificativa;
    },

    send() {
      if (!this.showFormGetJustificativa) {
        this.$refs.user.send();
      } else {
        this.$refs.justificativa.send();
      }
    },

    next() {
      if (this.showFormGetJustificativa) {
        return  this.finalizar();
      }
      if (this.situacao == "APROVADO") return this.finalizar();
      if (this.situacao == "REPROVADO") return this.openJustificativa();
    },

    

    finalizar() {
      this.$emit("finalizarDemonstrativo",this.payload)
    },

    openJustificativa() {
      this.showFormGetUser = false;
      this.showFormGetJustificativa = true;
    },
  },
  computed: {
    MESSAGE() {
      return this.situacao == "APROVADO"
        ? "Para seguir com a aprovação do Demonstrativo de Faturamento, por favor insira seu nome e seu e-mail abaixo:"
        : "Para prosseguir, por favor, insira seu nome e seu e-mail no formulário abaixo:";
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-situacao {
  display: flex;
  flex-direction: column;
  position: relative;
}
.sitacao-body {
  flex: 1;
  padding: 40px;
}
.situacao-footer {
  width: 100%;
  padding: 15px;

  border-top: 1px solid #ccc;
  display: flex;
  justify-content: center;

  button {
    max-width: 300px;
    min-height: 40px;
  }
}
</style>
