<template>
  <transition name="fade">
    <div
      class="modall__overlay"
      @click.self="close"
      data-cy="div-sobreposicao_click-modals"
    >
      <transition name="fade">
        <div
          class="modal__box-content"
          :style="{
            width,
            minWidth,
            maxWidth,
            minHeight,
            maxHeight,
            background: backgroundColor,
          }"
        >
          <div class="mtx-page-header" :style="{ background: backgroundColor }">
            <div>
              <slot name="header" />
              <div
                @click="close"
                v-if="iconClose"
                class="closeModal"
                data-cy="div-fechar_modal_base-modals"
              >
                &times;
              </div>
            </div>
          </div>
          <div class="modal__body">
            <slot name="body" />
          </div>
          <div v-if="!footerHide" class="modal__footer" :style="{ background: backgroundColor }">
            <slot name="footer" />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
    },
    minWidth: {
      type: String,
    },
    maxWidth: {
      type: String,
    },
    minHeight: {
      type: String,
    },
    maxHeight: {
      type: String,
    },
    footerHide: {
      type: Boolean,
    },
    iconClose: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: "#ffffff",
    },
  },
  data() {
    return {
      contentShow: false,
    };
  },
  mounted() {
    this.contentShow = true;
    document.body && (document.body.style.overflow = "hidden");
  },
  destroyed() {
    document.body.style.overflow = "auto";
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/breakpoints";
.modall__overlay {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 117.6vh;
  z-index: 5000;
  position: fixed;
  padding: 0 10px 0 10px;
  background: $color--black-overlay;
}
.mtx-page-header {
  width: 100%;
  display: flex;
  font-size: 21px;
  min-height: 57px;
  font-weight: 700;
  line-height: 120%;
  position: relative;
  text-align: center;
  align-items: center;
  letter-spacing: 1px;
  justify-content: center;
  background: $color--primary-white;
  border-bottom: 1px solid $color--primary--gray;
}
.modal__box-content {
  display: flex;
  overflow: hidden;
  margin: 6vh auto;
  max-height: 80vh;
  border-radius: 20px;
  flex-direction: column;
  background: $color--primary-white;
}
.modal__body {
  flex: 1;
  width: 100%;
  overflow: auto;
}
.modal__footer {
  display: flex;
  min-height: 66px;
  align-items: center;
  background: $color--primary-white;
  border-top: 1px solid $color--primary--gray;
}
.closeModal {
  top: 5px;
  right: 20px;
  color: #666;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
}
</style>
