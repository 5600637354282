<template>
  <div class="envio-email-recompra">
    <modal-base
      maxWidth="90%"
      v-if="showModalSubmitEmail"
      @closeModal="() => (showModalSubmitEmail = false)"
      data-cy="modal-type_fechar_modal_base_email-recompra"
    >
      <template #header>Enviar E-mail - Cálculo Finalizado</template>
      <template #body>
        <div class="envio-email-recompra__body">
          <div>
            <span class="box-title">Destinatários</span>
            <div class="box-content" style="flex-wrap: wrap; gap: 12px">
              <div
                v-for="(email, index) in emails"
                :key="index"
                class="email-tag"
              >
                {{ email }}
                <span
                  @click="removeEmail(index)"
                  data-cy="span-type_remover_email-recompra"
                >
                  X
                </span>
              </div>
              <input
                type="email"
                v-model="email"
                placeholder="Email"
                class="input-email"
                style="width: 230px"
                @keydown.tab="addEmail"
                @keydown.188="addEmail"
                @keydown.enter="addEmail"
                @keydown.delete="removeLastEmail"
                data-cy="input-type_adicionar-recompra"
              />
            </div>
          </div>
          <div>
            <span class="box-title">Assunto</span>
            <input class="box-content" v-model="assunto" />
          </div>
          <div style="margin-bottom: 20px">
            <vue-editor
              v-model="content"
              :editor-toolbar="customToolbar"
              style="height: 300px"
              data-cy="input-type_editor_tollbar-recompra"
            />
          </div>
        </div>
      </template>
      <template class="text-right" #footer>
        <div class="wrapper-buttons">
          <button
            @click="submitEmail"
            data-cy="button-type_enviar_email-recompra"
            class="btn-primary-full-filled btn-open btn-margin"
            :disabled="overlayLoading"
          >
            Enviar E-mail
          </button>
        </div>
      </template>
    </modal-base>
    <button
      class="btn btn-action-secondary-outlined"
      @click.self="showModalSubmitEmail = true"
      data-cy="button-type_mostrar_modal_enviar_email-recompra"
    >
      Enviar E-mail
    </button>
  </div>
</template>

<script>
import { CONFIRMATION } from "@/constants/strings";
import TypesToastENUM from "@/enums/TypesToastENUM";
import ContratosService from "@/services/ContratosService";
import { DateTime } from "luxon";
import ModalBase from "../modals/ModalBase.vue";
import getEconomicGroup from "@/enums/GrupoEconomicoENUM";

export default {
  props: {
    dataEmail: {
      type: Object,
    },
    typeEmail: {
      type: String,
    },
    contrato: {
      type: Object,
    },
  },
  data() {
    return {
      overlayLoading: false,
      content: "",
      email: "",
      emails: new Array(),
      assunto: "",
      showModalSubmitEmail: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ align: "" }, { align: "center" }],
        [{ list: "ordered" }],
      ],
    };
  },
  mounted() {
    this.setInfosEmail();
  },
  methods: {
    addEmail(event) {
      event.preventDefault();

      let valueInput = this.email.trim();

      let regexEmail =
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

      let emailIsValid = valueInput.match(regexEmail);

      let emailExists = this.emails.some((email) => email == valueInput);

      if (valueInput.length > 0 && emailIsValid && !emailExists) {
        this.emails.push(valueInput);

        this.email = "";
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    removeLastEmail(event) {
      let valueInput = event.target.value;

      if (valueInput.length === 0) {
        this.removeEmail(this.emails.length - 1);
      }
    },
    sendEmailRecompra(body) {
      ContratosService.sendEmailRecompraManual({
        idContrato: this.contrato.id,
        body,
      })
        .then((_) => {
          this.mxToggleToast({ message: CONFIRMATION.EMAIL.SENT });
          this.overlayLoading = false;
          this.showModalSubmitEmail = false;
        })
        .catch((error) => {
          this.overlayLoading = false;
          this.mxHandleRequestError(error, "ENVIAR_EMAIL");
        });
    },
    sendEmailCalculoFinalizado(body) {
      ContratosService.sendEmailCalculoFinalizado({
        idContrato: this.contrato.id,
        body,
      })
        .then((_) => {
          this.mxToggleToast({ message: CONFIRMATION.EMAIL.SENT });
          this.overlayLoading = false;
          this.showModalSubmitEmail = false;
        })
        .catch((error) => {
          this.overlayLoading = false;
          this.mxHandleRequestError(error, "ENVIAR_EMAIL");
        });
    },
    submitEmail() {
      this.overlayLoading = true;
      if (this.emails.length === 0) {
        this.overlayLoading = false;
        this.mxToggleToast({
          title: "Atenção",
          message: CONFIRMATION.EMAIL.NO_ADD,
          type: TypesToastENUM.DANGER,
        });
        return;
      }

      if (this.content === "") {
        this.overlayLoading = false;
        this.mxToggleToast({
          title: "Atenção",
          message: CONFIRMATION.EMAIL.NO_CONTENT,
          type: TypesToastENUM.DANGER,
        });
        return;
      }

      const emailBody = {
        destinatarios:
          this.email !== ""
            ? [...new Set([...this.emails, this.email])]
            : this.emails,
        assunto: this.assunto,
        corpo: this.content,
        encerramento: "<p>Este trecho vai no <b>fim</b> do email<p>",
      };

      if (this.typeEmail === "calculo_finalizado") {
        this.sendEmailCalculoFinalizado(emailBody);
      } else {
        this.sendEmailRecompra(emailBody);
      }
    },

    setInfosEmail() {
      const { dataReferencia, destinatarios, razaoSocial } = this.dataEmail;
      const { month, year } = DateTime.fromISO(dataReferencia);

      let monthFormatter = month < 10 ? `0${month}` : month;

      this.assunto = `[ ${getEconomicGroup(this?.contrato?.grupoEconomico)} ] - Demonstrativo de Faturamento ${razaoSocial} - ${monthFormatter}/${year}`;

      this.emails =
        this.contrato.grupoEconomico === "Newave"
          ? destinatarios.filter(
              (email) => !email.toUpperCase().includes("MATRIXENERGIA")
            )
          : destinatarios;

      this.content = `Prezado(s), Segue abaixo demonstrativo do Faturamento de Energia correspondente ao mês de ${monthFormatter}/${year}, 
      referente ao cliente ${razaoSocial}. Solicitamos a confirmação dos montantes a serem faturados até o terceiro dia útil, 
      visando prosseguir com o faturamento de energia e envio das Notas Fiscais.`;
    },
  },

  watch: {
    dataEmail() {
      const { dataReferencia, destinatarios, razaoSocial } = this.dataEmail;
      const { month, year } = DateTime.fromISO(dataReferencia);

      let monthFormatter = month < 10 ? `0${month}` : month;

      this.assunto = `[ Matrix ] - Demonstrativo de Faturamento ${razaoSocial} - ${monthFormatter}/${year}`;

      this.emails = destinatarios;

      this.content = `Prezado(s), Segue abaixo demonstrativo do Faturamento de Energia correspondente ao mês de ${monthFormatter}/${year}, referente ao cliente ${razaoSocial}. Solicitamos a confirmação dos montantes a serem faturados até o terceiro dia útil, visando prosseguir com o faturamento de energia e envio das Notas Fiscais.`;
    },
  },
  components: {
    ModalBase,
  },
};
</script>

<style lang="scss" scoped>
.envio-email-recompra {
  &__body {
    width: 100%;
    padding: 30px 50px;
    font-size: 1rem;
    line-height: 29px;
    color: #2a2a2a;
  }

  .btn-open {
    width: 250px;

    padding: 8px;

    border-radius: 12px;

    font-weight: bold;
  }
}

.box-title {
  font-weight: 300;
  font-size: 16px;

  color: #2a2a2a;

  margin-bottom: 20px;
}

.box-content {
  display: flex;
  align-items: center;

  width: 100%;

  padding: 10px;

  background: #ffffff;

  border: 1px solid #b4b7ba;
  border-radius: 12px;

  margin-bottom: 10px;

  input {
    width: 100%;
    height: 100%;

    border: none;
    background: none;

    outline: none;
  }
}

.email-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  width: fit-content;

  padding: 0.2rem 0.5rem;

  line-height: 30px;

  font-weight: 700;

  background: #fffaf8;
  color: #f2994a;

  border: 1px solid #c7c9cb;
  border-radius: 9px;

  span {
    color: #f2994a;

    font-size: 14px;

    cursor: pointer;

    opacity: 0.75;
  }
}

.wrapper-buttons {
  width: 100%;
  text-align: right;
}

.btn-margin {
  margin-right: 20px;
}
</style>
