import DynamicTooltip from "@/components/DynamicTooltip.vue";
import OverlayLoader from "@/components/OverlayLoader.vue";
import OverlayLoaderNewave from "@/components/OverlayLoaderNewave.vue";
import Vue from "vue";

const components = [
  ["overlay-loader", OverlayLoader],
  ["dynamic-tooltip", DynamicTooltip],
  ["overlay-loader-newave", OverlayLoaderNewave],
];

components.map((component) => {
  Vue.component(component[0], component[1], component[2]);
});
