import { render, staticRenderFns } from "./ReductionAmountsContestedNewave.vue?vue&type=template&id=852bfe72&scoped=true&"
import script from "./ReductionAmountsContestedNewave.vue?vue&type=script&lang=js&"
export * from "./ReductionAmountsContestedNewave.vue?vue&type=script&lang=js&"
import style0 from "./ReductionAmountsContestedNewave.vue?vue&type=style&index=0&id=852bfe72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "852bfe72",
  null
  
)

export default component.exports