import store from "@/store";
import { loginRedirect } from "@/utils/authentication";
import axios from "axios";

class Services {
  blackListRoutes = ["/demonstrativo"];

  constructor(endpoint) {
    this._url = "";
    this._api = axios.create({
      baseURL: process.env.VUE_APP_ROOT_BILLING + endpoint,
      timeout: 200000,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Forwarded-Prefix": process.env.VUE_APP_PROXY_FORWARDED_PREFIX,
      },
      validateStatus: function (status) {
        return status >= 200 && status <= 299;
      },
    });

    this._api.interceptors.request.use(
      async (request) => {
        let token = null;
        if (process.env.RUNNING_TESTS) {
          token = process.env.AUTH_TOKEN;
        } else {
          token = window.sessionStorage.getItem(process.env.VUE_APP_TOKEN_KEY);
        }
        if (token) {
          request.headers.Authorization = `Bearer ${token}`;
        }

        if (this.blackListRoutes.includes(endpoint)) {
          delete request.headers.Authorization;
        }

        store.dispatch("ActionResetPendencies");

        return request;
      },
      (error) => {
        if (error.response.status === 401) {
          loginRedirect();
        }
        return error;
      }
    );
  }

  static getInstanceAxios(options) {
    return axios.create({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("b-token")}`,
      },
      ...options,
    });
  }

  static getInstanceAxiosFormData(options) {
    return axios.create({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("b-token")}`,
      },

      ...options,
    });
  }

  createQueryParams(request) {
    if (!request) return;

    let keys = Object.keys(request);
    this._url += "?";
    let count = 0;
    for (let key of keys) {
      if (
        request[key] == null ||
        request[key] === undefined ||
        request[key] === "undefined"
      ) {
        continue;
      }

      if (Array.isArray(request[key])) {
        this._url +=
          (count > 0 ? "&" : "") +
          request[key]
            .map((e) => `${key}${encodeURIComponent("[]")}=${e}`)
            .join("&");
        count++;
        continue;
      }

      this._url += `${count > 0 ? "&" : ""}${key}=${request[key]}`;
      count++;
    }
  }

  replaceParameters(request) {
    let keys = Object.keys(request);
    for (let key of keys) {
      this._url = this._url.replace(`\{${key}\}`, request[key]);
    }
  }

  hasParameters() {
    return /(\{[a-zA-Z]\})+/.test(this._url);
  }

  get(request = null, queryParam = false) {
    if (this.hasParameters() && !queryParam) {
      this.replaceParameters(request);
    } else if (queryParam) {
      this.createQueryParams(request);
    }
    return this._api.get(this._url, { data: null });
  }

  getAttachment(url) {
    return this._api.get(url, { responseType: "blob" });
  }

  postParam(request = null, queryParam = false) {
    if (this.hasParameters() && !queryParam) {
      this.replaceParameters(request);
    } else if (queryParam) {
      this.createQueryParams(request);
    }
    return this._api.post(this._url);
  }

  post(request, queryParam = false, config) {
    if (queryParam) {
      this.createQueryParams(request);
    }
    return this._api.post(this._url, request, config);
  }

  put(request) {
    return this._api.put(this._url, request);
  }

  patch(request) {
    return this._api.patch(this._url, request);
  }

  putParam(request = null, queryParam = false) {
    if (this.hasParameters() && !queryParam) {
      this.replaceParameters(request);
    } else if (queryParam) {
      this.createQueryParams(request);
    }
    return this._api.put(this._url);
  }

  delete(request) {
    this.replaceParameters(request);
    let body = null;
    if (request) {
      body = { data: request };
    }
    return this._api.delete(this._url, body);
  }
}

export default Services;
