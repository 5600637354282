<template>
  <div class="page">
    <!-- componentes pages -->
    <transition name="fademodal">
      <modal-get-data-user
        v-if="modalGetDataUserShow"
        @userResult="getUserResult"
        @userResultForManual="contestForValue"
        @closeGetDataUser="closeGetDataUser"
        :recompraMannual="recompraMannual"
        :title="'APROVAR PROPOSTA DE REDUÇÃO DE MONTANTES'"
        :text="'Para seguir com a confirmação da Proposta de Redução de Montantes, por favor insira seu nome e seu e-mail abaixo:'"
        style="z-index: 1000"
      />
      <modal-proposal-confirm
        v-if="modalPrposalConfirmShow"
        style="z-index: 1000"
        @nextConfirmModal="nextConfirmModal"
      />
    </transition>
    <!-- -->
    <div v-if="spinner" class="mtx-spinner"></div>
    <div class="breadcrumbs">
      <span class="mr-2">Proposta de Redução de Montantes</span> >
      <span class="mr-2 ml-2">Preços diferentes</span> >
      <span class="mr-2 ml-2" :class="{ 'color-crump': !isSummary }"
        >Ajustar Volume</span
      >
    </div>
    <div class="box-reduction-amount">
      <div class="box-head">PROPOSTA DE REDUÇÃO DE MONTANTES</div>
      <div class="box-content">
        <div
          v-if="!priceIsValidChild(data.filter((g) => g.isAgrupador == false))"
          class="alert-msg"
        >
          <div class="alert-icon">
            <img :src="mountImg('/images/mtx/icon-alert-triangulo.svg')" />
          </div>
          <div class="alert-text">
            <b>ATENÇÃO: </b>Devido às condições de mercado, favor entrar em
            contato com seu Executivo de Relacionamento para o fechamento da
            operação.
          </div>
        </div>
        <div class="box-infos">
          <div class="info-row info-top mt-4">
            <div class="info-item item-razaoSocial">
              <div class="info-item-title">Submercado:</div>
              <div class="info-item-value">
                <b>{{ data.filter((g) => g.isAgrupador)[0].submercado }} </b>
              </div>
            </div>
            <div class="info-item"></div>
            <div class="info-item"></div>

            <div class="info-item">
              <div class="info-item-title">Tipo de Energia :</div>
              <div class="info-item-value">
                <b>{{ data.filter((g) => g.isAgrupador)[0].tipoEnergia }} </b>
              </div>
            </div>
          </div>
          <hr />
          <div v-if="!isSummary" class="info-row">
            <div class="info-item">
              <div class="info-item-title">Take Mínimo:</div>
              <div class="info-item-value">
                <b>{{
                  data.filter((g) => g.isAgrupador == true)[0].takeMinimo
                    | volume
                }}</b>
              </div>
            </div>
            <div class="info-item">
              <div class="info-item-title">Consumo Total Apurado:</div>
              <div class="info-item-value">
                <b
                  >{{
                    data.filter((g) => g.isAgrupador == true)[0].consumoApurado
                      | volume
                  }}
                  MWh</b
                >
              </div>
            </div>
            <div class="info-item">
              <div class="info-item-title">Preço (PLD + Spread):</div>
              <div class="info-item-value">
                <b
                  ><span>
                    (R$
                    {{ data.filter((g) => g.isAgrupador == true)[0].pld }}
                    +
                    {{
                      data.filter((g) => g.isAgrupador == true)[0].spread
                    }})</span
                  ></b
                ><br />
                <b
                  >R$
                  {{
                    data.filter((g) => g.isAgrupador == true)[0].pld +
                    data.filter((g) => g.isAgrupador == true)[0].spread
                  }}
                </b>
              </div>
            </div>

            <div class="info-item">
              <div class="info-item-title">Preço válido por:</div>
              <div class="info-item-value">
                <div class="">
                  <timer @finished="timerfinished" :periodo="3599"></timer>
                </div>
                <div class="text-timer">
                  Se fechar ou atualizar a tela, o preço pode sofrer alterações
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-distribution-counter">
          <div class="counter-item"><b>Distribuição por Unidades</b></div>
          <div v-if="!isSummary && !montantIsValid" class="counter-item">
            <span class="color-red"
              >Verifique os valores inseridos em "Montante a reduzir" antes de
              prosseguir.</span
            >
          </div>
          <div v-if="!isSummary" class="counter-item">
            <span v-if="volumeRemains > 0" class="mr-2">Falta distribuir:</span>
            <span v-if="volumeRemains < 0" class="mr-2"
              >Há volume excedente de:</span
            >
            <span v-if="volumeRemains != 0" style="color: #f7685b"
              >{{ volumeRemains | volume | positive }} MWh</span
            >
          </div>
        </div>
        <mtx-scroll>
          <div class="box-grid">
            <div class="grid-header">
              <div style="padding-left: 30px" class="grid-cell">CNPJ</div>
              <div class="grid-cell">Razão Social</div>
              <div style="text-align: center" class="grid-cell">
                Consumo Apurado
              </div>
              <div style="text-align: center" class="grid-cell">
                Distribuição do Take Min.
              </div>
              <div style="text-align: center" class="grid-cell">
                Montante a reduzir
              </div>
              <div style="text-align: center" class="grid-cell">
                Novo Montante
              </div>
              <div style="text-align: center" class="grid-cell">
                Preço Original
              </div>
              <div style="text-align: center" class="grid-cell">Novo Preço</div>
            </div>
            <div
              v-for="(contract, indexContract) in data.filter(
                (g) => g.isAgrupador == false
              )"
              :key="`indexContract${indexContract}`"
              class="grid-body"
            >
              <div style="padding-left: 30px" class="grid-cell">
                {{ contract.cnpj }}
              </div>
              <div class="grid-cell">{{ contract.razaoSocial }}</div>
              <div style="text-align: center" class="grid-cell">
                {{ contract.consumoApurado | volume }}
              </div>
              <div style="text-align: center" class="grid-cell">
                <span v-if="isSummary">{{ contract.volumeDistribuido }}</span>
                <vue-numeric
                  v-if="!isSummary"
                  v-debounce:50ms="calculate"
                  class="numeric-field-grid"
                  :class="{
                    'border-red': priceIsValid(contract.precoNovo) == false,
                  }"
                  currency=""
                  :minus="true"
                  :precision="3"
                  separator="."
                  v-model="contract.volumeDistribuido"
                ></vue-numeric>
              </div>
              <div style="text-align: center" class="grid-cell">
                <span v-if="isSummary">{{ contract.montanteReduzir }}</span>
                <vue-numeric
                  v-if="!isSummary"
                  v-debounce:50ms="calculate"
                  class="numeric-field-grid"
                  :class="{
                    'border-red':
                      contract.montanteNovo < 0 ||
                      priceIsValid(contract.precoNovo) == false,
                  }"
                  currency=""
                  :minus="true"
                  :precision="3"
                  separator="."
                  v-model="contract.montanteReduzir"
                ></vue-numeric>
              </div>
              <div
                style="text-align: center"
                :class="{ 'color-red': contract.montanteNovo < 0 }"
                class="grid-cell"
              >
                {{ contract.montanteNovo | volume }}
              </div>
              <div style="text-align: center" class="grid-cell">
                {{ formatPrice(contract.precoOriginal) }}
              </div>
              <div style="text-align: center" class="grid-cell">
                <span
                  :class="{
                    'color-red': !priceIsValid(contract.precoNovo),
                  }"
                >
                  R$ {{ formatPrice(contract.precoNovo) }}
                </span>
              </div>
            </div>
            <div class="grid-total">
              <div style="padding-left: 30px" class="grid-cell">TOTAL</div>
              <div class="grid-cell"></div>
              <div class="grid-cell"></div>
              <div style="text-align: center" class="grid-cell">
                {{ totalDistribute | volume }}
              </div>
              <div style="text-align: center" class="grid-cell">
                {{ totalAjuste | volume }}
              </div>
              <div style="text-align: center" class="grid-cell">
                {{ totalResultante | volume }}
              </div>
              <div class="grid-cell"></div>
              <div class="grid-cell"></div>
            </div>
          </div>
        </mtx-scroll>
        <div class="box-buttons">
          <button @click="preview" class="mtx-btn btn-border mr-2">
            VOLTAR
          </button>
          <button
            :disabled="
              (volumeRemains != 0 && !isSummary) ||
              (!montantIsValid && !isSummary) ||
              !priceIsValidChild(data.filter((g) => g.isAgrupador == false))
            "
            @click="next"
            style="width: 250px"
            class="mtx-btn btn-orange ml-2"
          >
            AVANÇAR
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import RepurchaseStatusService from "../../../services/repurchase-status";
//import HotsiteService from "../../../services/hotsite";
//import HostsiteRepurchaseService from "../../../services/hotsite-repurchase";
import ModalGetDataUser from "../../ModalGetDataUser.vue";
import ModalProposalConfirm from "../proposal-repurchase/ModalConfimationProposalNewave.vue";
//import Timer from "../../../components/timmer/timmer.vue";
//import Filters from "../../../mtx-utils/MtxFilters.vue";
export default {
  //extends: Filters,
  props: ["id", "contrato"],
  data() {
    return {
      modalPrposalConfirmShow: false,
      modalGetDataUserShow: false,
      spinner: false,
      totalDistribute: 0,
      totalAjuste: 0,
      totalResultante: 0,
      volumeRemains: 0,
      maximumPrice: 650,
      minimumPrice: 49.77,
      recompraMannual: false,
      posvenda: "",
      montantIsValid: true,
      data: [
        {
          isAgrupador: true,
          consumoApuradoTotal: 0,
          contrato: 0,
          precoOriginal: 0,
          takeMinimo: 0,
          cnpj: "",
          razaoSocial: "",
          submercado: "",
          tipoEnergia: "",
          spread: 0,
          pld: 0,
        },
      ],
      isValid: true,
      isPositive: true,
      isSummary: false,
    };
  },
  mounted() {
    this.getData();
    this.listPosVenda();
  },
  watch: {
    data: {
      handler: function (val, oldVal) {
        // this.calculate();
      },
      deep: true,
    },
  },
  methods: {
    formatPrice: function (value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    timerfinished() {
      document.location.reload();
    },
    listPosVenda() {
      HostsiteRepurchaseService.getPosVenda({
        processId: this.id,
        contrato: this.contrato.wbcContract,
      }).then((response) => {
        this.posvenda = response;
      });
    },
    priceIsValid(value) {
      if (value > this.minimumPrice && value < this.maximumPrice) {
        return true;
      } else {
        return false;
      }
    },
    priceIsValidChild(value) {
      let valid = true;
      value.forEach((contrato) => {
        if (
          contrato.precoNovo > this.minimumPrice &&
          contrato.precoNovo < this.maximumPrice
        ) {
        } else {
          valid = false;
        }
      });
      return valid;
    },
    montanteIsValidChild(value) {
      this.montantIsValid = true;
      let temp = true;
      this.data
        .filter((g) => g.isAgrupador == false)
        .forEach((contract) => {
          if (contract.montanteNovo < 0) {
            temp = false;
          }
        });
      this.montantIsValid = temp;
    },
    getUserResult(value) {
      this.modalGetDataUserShow = false;
      value.typeRecompra = "PRECOS_DIFERENTES";
      this.$emit("saveLogRecompra", value);
    },
    closeGetDataUser() {
      this.modalGetDataUserShow = false;
    },

    preview() {
      if (this.isSummary) {
        this.isSummary = false;
      } else {
        this.spinner = true;
        this.saveStatus("DEMONSTRATIVO");
      }
    },

    saveStatus(type) {
      RepurchaseStatusService.updateStatus({
        idProcess: parseInt(this.id),
        status: type,
      })
        .then(() => {
          if (type == "APROVADO") {
            this.modalGetDataUserShow = false;
            document.location.reload();
          } else {
            document.location.reload();
          }
        })
        .catch((e) => {
          this.spinner = false;
        });
    },

    calculateInicial() {
      this.isPositive = true;
      this.isValid = true;
      this.newAmountIsValid = true;
      this.data.forEach((contract) => {
        if (contract.isAgrupador == false) {
          contract.volumeDistribuido = parseFloat(
            contract.volumeDistribuido.toFixed(3)
          );

          contract.notionalOriginal =
            contract.precoOriginal * contract.volumeDistribuido;
          contract.notionalRecompra =
            contract.montanteReduzir * contract.precoAjuste;
          contract.notionalFinal =
            contract.notionalOriginal - contract.notionalRecompra;
          contract.precoNovo =
            contract.notionalFinal /
            (contract.volumeDistribuido - contract.montanteReduzir);

          contract.takeMinimo = contract.volumeDistribuido;
          contract.montanteNovo =
            contract.takeMinimo - contract.montanteReduzir;

          if (!this.priceIsValid(contract.precoNovo)) {
            this.isValid = false;
          }
          if (contract.precoNovo < 0) {
            this.isPositive = false;
          }

          if (contract.montanteNovo < 0.1) {
            this.newAmountIsValid = false;
          }
        }
      });

      let distributeChildTemp = 0;
      this.data
        .filter((g) => g.isAgrupador == false)
        .forEach((contract) => {
          distributeChildTemp =
            distributeChildTemp +
            parseFloat(contract.volumeDistribuido.toFixed(3));
        });
      if (
        distributeChildTemp !==
        this.data.filter((g) => g.isAgrupador == true)[0].takeMinimo
      ) {
        this.data.filter((g) => g.isAgrupador == false)[0].volumeDistribuido =
          this.data.filter((g) => g.isAgrupador == false)[0].volumeDistribuido +
          (this.data.filter((g) => g.isAgrupador == true)[0].takeMinimo -
            distributeChildTemp);
      }

      this.data;

      this.calculateTotais();
      this.montanteIsValidChild();
    },

    calculate() {
      this.isPositive = true;
      this.isValid = true;
      this.newAmountIsValid = true;
      this.data.forEach((contract) => {
        if (contract.isAgrupador == false) {
          contract.notionalOriginal =
            contract.precoOriginal * contract.volumeDistribuido;
          contract.notionalRecompra =
            contract.montanteReduzir * contract.precoAjuste;
          contract.notionalFinal =
            contract.notionalOriginal - contract.notionalRecompra;
          contract.precoNovo =
            contract.notionalFinal /
            (contract.volumeDistribuido - contract.montanteReduzir);

          contract.takeMinimo = contract.volumeDistribuido;
          contract.montanteNovo =
            contract.takeMinimo - contract.montanteReduzir;

          if (!this.priceIsValid(contract.precoNovo)) {
            this.isValid = false;
          }
          if (contract.precoNovo < 0) {
            this.isPositive = false;
          }

          if (contract.montanteNovo < 0.1) {
            this.newAmountIsValid = false;
          }
        }
      });
      this.data;

      this.calculateTotais();
      this.montanteIsValidChild();
    },

    calculateTotais() {
      (this.totalDistribute = 0),
        (this.totalAjuste = 0),
        (this.totalResultante = 0),
        this.data.forEach((contract) => {
          if (!contract.isAgrupador) {
            this.totalDistribute =
              this.totalDistribute + contract.volumeDistribuido;
            this.totalAjuste = this.totalAjuste + contract.montanteReduzir;
            this.totalResultante = this.totalResultante + contract.montanteNovo;
          }
        });
      this.volumeRemains =
        this.data.filter((g) => g.isAgrupador)[0].takeMinimo -
        this.totalDistribute.toFixed(3);
    },

    getData() {
      (this.spinner = true),
        HostsiteRepurchaseService.getData({ processId: this.id })
          .then((response) => {
            let requestPost = response.map((g) => {
              g.precoAjuste = g.pld + g.spread;
              g.isPrecoUnico = true;
              g.notionalFinal = 0;
              g.notionalOriginal = 0;
              g.notionalRecompra = 0;
              g.precoNovo = 0;
              g.montanteReduzir = "";
              g.volumeDistribuido = 0;
              g.montanteNovo = 0;
              if (g.isAgrupador == false) {
                g.volumeDistribuido =
                  (g.consumoApurado /
                    response.filter((g) => g.isAgrupador == true)[0]
                      .consumoApurado) *
                  response.filter((g) => g.isAgrupador == true)[0].takeMinimo;

                g.montanteReduzir = g.volumeDistribuido - g.consumoApurado;
                g.montanteNovo =
                  response.filter((g) => g.isAgrupador == true)[0].takeMinimo -
                  g.montanteReduzir;
              }

              return g;
            });
            this.data = requestPost.reverse();
            this, this.calculateInicial();
            this.spinner = false;
          })
          .catch((e) => {
            this.spinner = false;
          });
    },

    insertSumOfChildren() {
      this.data.filter((g) => g.isAgrupador == true)[0].montanteNovo = 0;
      this.data.filter((g) => g.isAgrupador == true)[0].montanteReduzir = 0;
      this.data.forEach((contract) => {
        this.data.filter((g) => g.isAgrupador == true)[0].montanteNovo =
          this.data.filter((g) => g.isAgrupador == true)[0].montanteNovo +
          parseFloat(contract.montanteNovo);
        this.data.filter((g) => g.isAgrupador == true)[0].montanteReduzir =
          this.data.filter((g) => g.isAgrupador == true)[0].montanteReduzir +
          parseFloat(contract.montanteReduzir);
      });
    },

    next() {
      this.spinner = true;
      this.insertSumOfChildren();
      if (!this.isPositive) {
        this.mxShowModalAlert({
          title: "Atenção",
          message: "Novo preço não pode ser negativo negativo",
          type: "confirm",
        }).then(() => {});
      } else {
        this.spinner = false;

        HotsiteService.getBilling({ processId: this.id }).then((response) => {
          //response.statusPrice = "expirado";
          this.spinner = false;

          if (!this.isValid || response.statusPrice != "APROVADO") {
            let html = `Devido à condição de mercado, 
        essa proposta de redução de montantes será tratada por   
        seu executivo de relacionamento.<br>
        <b>Deseja continuar assim ?</b>
 `;
            this.mxShowModalAlert({
              title: "Atenção",
              message: html,
              type: "cancelar",
            })
              .then(() => {
                this.recompraMannual = true;
                this.modalGetDataUserShow = true;
              })
              .catch((e) => {});
          } else {
            if (!this.newAmountIsValid) {
              this.mxShowModalAlert({
                title: "Atenção",
                message: "Novo Montante deve ser maior que <b>0,1 MWh</b>",
                type: "confirm",
              }).then(() => {});
            } else {
              this.modalGetDataUserShow = true;
            }
          }
        });
      }
    },

    contestForValue(value) {
      this.modalGetDataUserShow = false;

      this.spinner = true;
      let requestConfirm = {
        id: 0,
        idProc: this.id,
        justify: "",
        status: "RECOMPRA_MANUAL", //status: "REPROVADO",
      };

      HotsiteService.updateStatus(requestConfirm)
        .then(() => {
          let statusRequest = {
            idProcess: this.id,
            status: "RECOMPRA_MANUAL",
          };

          RepurchaseStatusService.updateStatus(statusRequest).then(() => {
            let requestAproval = {
              createdAt: null,
              email: value.infos.email,
              id: 0,
              name: value.infos.name,
              processId: this.id,
              type: "RC",
            };
            HotsiteService.logAproval(requestAproval).then((response) => {
              this.$emit("completeTask");
            });
          });
          this.spinner = false;
        })
        .catch(() => {
          this.spinner = false;
        });
    },

    post() {
      this.spinner = true;
      HostsiteRepurchaseService.saveCalculation(this.data, this.id)
        .then((response) => {
          this.spinner = false;
          this.$emit("finalizarRecompra", "PRECOS_DIFERENTES");
        })
        .catch((e) => {
          this.spinner = false;
        });
    },

    modalaproved() {
      this.modalPrposalConfirmShow = true;
    },

    nextConfirmModal() {
      this.spinner = false;
      this.saveStatus("APROVADO");
    },

    formatVolume(value) {
      if (!value || isNaN(value) || !isFinite(value)) {
        return "0,000";
      }
      var m = parseFloat(value).toFixed(3);
      m = m.toString().replace(".", ",");
      var parts = m.split(",");
      if (parts[0].toString().length > 3) {
        parts[0] = [parts[0].slice(0, -3), ".", parts[0].slice(-3)].join("");
      }
      let result = parts[0] + "," + parts[1];
      return result.replace("-.", "--");
    },
  },
  components: {
    ModalGetDataUser,
    ModalProposalConfirm,
    //Timer,
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(117.6vh - 86px);
}
.breadcrumbs {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  background: #ffffff;
  border-radius: 5px;
  width: 80%;
  display: flex;
  justify-items: center;
  padding: 5px 0 5px 20px;
  margin-top: 30px;
  margin-bottom: 39px;
  flex-wrap: wrap;
}
.box-reduction-amount {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 80%;
}
.alert-msg {
  width: 100%;
  background: #f8f8f8;
  border-radius: 5px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #505050;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.alert-icon {
  padding: 0 10px 0 10px;
  img {
    width: 20px;
    margin-top: -2px;
  }
}
.box-head {
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #929292;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 0 8px 20px;
  display: flex;
  align-items: center;
}
.box-content {
  padding: 20px;
}

.box-infos {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 20px 40px 20px 0px;
}
.info-row {
  display: flex;
  justify-content: flex-start;
  @include tablet {
    flex-direction: column;
  }
}
.info-item {
  width: 25%;
  padding-left: 70px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  @include tablet {
    width: 100%;
    text-align: center;
    padding-left: 0px;
    margin-bottom: 10px;
  }
}
.info-item-title {
  margin-bottom: 2px;
}
.info-item-value {
  margin-top: 2px;
}
.numeric-field-grid {
  background: #ebebeb;
  border-radius: 3px;
  border: none;
  width: 100px;
  text-align: center;
  &:disabled {
    background: #c4c4c4;
    color: #c4c4c4;
  }
}
.box-distribution-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  margin-top: 41px;
  margin-bottom: 25px;
  @include tablet {
    flex-direction: column;
  }
}
.counter-item {
  @include tablet {
    margin-bottom: 10px;
  }
}
.grid-header {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #929292;
  padding: 7px 0 7px 0;
}
.grid-body {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #505050;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 7px 0 7px 0;
}
.grid-total {
  display: flex;
  justify-content: space-between;
  background: #e8e8e8;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #505050;
  padding: 7px 0 7px 0;
}
.grid-cell {
  width: 14.7% !important;
  padding-right: 30px;
}
.box-buttons {
  margin-top: 31px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include tablet {
    justify-content: center;
  }
}
.btn-orange {
  width: 174px;
  height: 42px;
  background: #0b2b40;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background: rgba($color: #0b2b40, $alpha: 0.8);
    color: #fff;
  }
  &:disabled {
    background: rgba($color: #ccc, $alpha: 0.8);
    cursor: not-allowed;
  }
}
.btn-border {
  width: 174px;
  height: 42px;
  border: 1px solid #505050;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
}
.color-red {
  color: red;
  font-weight: 600;
}
.color-crump {
  color: #0b2b40;
}
.border-red {
  border: red 1px solid;
}
.info-timer {
  text-align: right;
  width: 50%;
  @include tablet {
    text-align: center;
    width: 100%;
  }
}
.text-timer {
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #505050;
  padding: 7px 0 7px 0;
}
.info-top {
  justify-content: space-between;
  @include tablet {
    justify-content: center;
  }
}
</style>