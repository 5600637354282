<template>
  <div class="page">
    <!-- componentes pages -->
    <transition name="fademodal">
      <modal-get-data
          v-if="modalGetDataUserShow"
          @userResult="getUserResult"
          @userResultForManual="contestForValue"
          @closeGetDataUser="closeGetDataUser"
          :recompraMannual="recompraMannual"
          :title="'APROVAR PROPOSTA DE REDUÇÃO DE MONTANTES'"
          :text="'Para seguir com a confirmação da Proposta de Redução de Montantes, por favor insira seu nome e seu e-mail abaixo:'"
          style="z-index: 1000"
      />
      <modal-proposal-confirm
          v-if="modalPrposalConfirmShow"
          style="z-index: 1000"
          @nextConfirmModal="nextConfirmModal"
      />
    </transition>
    <!-- -->
    <div v-if="spinner" class="mtx-spinner"></div>
    <div class="breadcrumbs">
      <span class="mr-2">Proposta de Redução de Montantes</span> >
      <span v-if="isRateio" class="mr-2 ml-2">Preço Único ></span>
      <span class="mr-2 ml-2" :class="{ 'color-crump': !isSummary }">
        Distribuir Volumes</span
      >
      >
      <span class="mr-2 ml-2" :class="{ 'color-crump': isSummary }"
      >Resumo</span
      >
    </div>
    <div class="box-reduction-amount">
      <div class="box-head">PROPOSTA DE REDUÇÃO DE MONTANTES</div>
      <div class="box-content">
        <div
            v-if="
            data.filter((g) => g.isAgrupador)[0].precoNovo &&
            !priceIsValid(data.filter((g) => g.isAgrupador)[0].precoNovo)
          "
            class="alert-msg"
        >
          <div class="alert-icon">
            <img :src="mountImg('/images/mtx/icon-alert-triangulo.svg')" />
          </div>
          <div class="alert-text">
            <b>ATENÇÃO: </b> Devido às condições de mercado, favor entrar em
            contato com seu Executivo de Relacionamento para o fechamento da
            operação.
          </div>
        </div>
        <div class="box-infos pr-4">
          <div class="info-row">
            <div class="info-item item-razaoSocial">
              <div class="info-item-title">Razão Social:</div>
              <div class="info-item-value">
                <b>{{ data.filter((g) => g.isAgrupador)[0].razaoSocial }} </b>
              </div>
            </div>

            <div class="info-item">
              <div class="info-item-title">CNPJ :</div>
              <div class="info-item-value">
                <b>{{ data.filter((g) => g.isAgrupador)[0].cnpj }} </b>
              </div>
            </div>
          </div>
          <div class="info-row mt-4">
            <div class="info-item item-razaoSocial">
              <div class="info-item-title">Submercado:</div>
              <div class="info-item-value">
                <b>{{ data.filter((g) => g.isAgrupador)[0].submercado }} </b>
              </div>
            </div>

            <div class="info-item">
              <div class="info-item-title">Tipo de Energia :</div>
              <div class="info-item-value">
                <b>{{ data.filter((g) => g.isAgrupador)[0].tipoEnergia }} </b>
              </div>
            </div>
          </div>
          <hr />

          <div v-if="!isSummary" style="margin-bottom: 24px" class="info-row">
            <div class="info-item">
              <div class="info-item-title">Take Mínimo:</div>
              <div class="info-item-value">
                <b
                >{{
                    data.filter((g) => g.isAgrupador == true)[0].takeMinimo
                        | volume
                  }}
                  MWh</b
                >
              </div>
            </div>
            <div class="info-item">
              <div class="info-item-title">Preço original:</div>
              <div class="info-item-value">
                <b
                >R$
                  {{
                    data.filter((g) => g.isAgrupador == true)[0].precoOriginal
                  }}
                </b>
              </div>
            </div>

            <div class="info-item">
              <div class="info-item-title">Preço (PLD + Spread):</div>
              <div class="info-item-value">
                <b
                ><span>
                    (R$
                    {{ data.filter((g) => g.isAgrupador == true)[0].pld }}
                    +
                    {{
                    data.filter((g) => g.isAgrupador == true)[0].spread
                  }})</span
                ></b
                ><br />
                <b
                >R$
                  {{
                    data.filter((g) => g.isAgrupador == true)[0].pld +
                    data.filter((g) => g.isAgrupador == true)[0].spread
                  }}
                </b>
              </div>
            </div>
            <div class="info-item item-timer">
              <div class="info-item-title">Preço válido por:</div>
              <div class="info-item-value">
                <timmer @finished="timerfinished" :periodo="3599"></timmer>
                <div class="text-timer">
                  Se fechar ou atualizar a tela, o preço pode sofrer alterações
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isSummary" class="info-row">
            <div class="info-item">
              <div class="info-item-title">Consumo Total Apurado:</div>
              <div class="info-item-value">
                <b
                >{{
                    data.filter((g) => g.isAgrupador == true)[0].consumoApurado
                  }}
                  MWh</b
                >
              </div>
            </div>
            <div class="info-item">
              <div class="info-item-title">Montante a Reduzir :</div>
              <div class="info-item-value">
                <input
                    type="number"
                    class="numeric-field-grid"
                    @input="changeMontanteReduzir"
                    @blur="formatMontanteReduzir"
                    step="0.001"
                    min="1"
                    v-model="
                    data.filter((g) => g.isAgrupador == true)[0].montanteReduzir
                  "
                />
                <b class="ml-2">MWh</b>
              </div>
            </div>

            <div class="info-item">
              <div class="info-item-title">Novo Montante :</div>
              <div
                  class="info-item-value"
                  :class="{
                  'color-red':
                    data.filter((g) => g.isAgrupador == true)[0].montanteNovo <
                      0.1 ||
                    data.filter((g) => g.isAgrupador == true)[0].montanteNovo ==
                      '',
                }"
              >
                <b
                >{{
                    data.filter((g) => g.isAgrupador == true)[0].montanteNovo
                        | volumeEmpty
                  }}
                  MWh</b
                >
              </div>
            </div>
            <div class="info-item">
              <div class="info-item-title">Novo Preço :</div>
              <div class="info-item-value">
                <b>
                  <span
                      :class="{
                      'color-red': !priceIsValid(
                        data.filter((g) => g.isAgrupador == true)[0].precoNovo
                      ),
                    }"
                  >
                    R$
                    {{ data.filter((g) => g.isAgrupador == true)[0].precoNovo }}
                  </span>
                </b>
              </div>
            </div>
          </div>
          <div
              v-if="isSummary"
              style="justify-content: flex-start"
              class="info-row"
          >
            <div style="wisth: 33.33%" class="info-item">
              <div class="info-item-title">Submercado:</div>
              <div class="info-item-value">
                <b>{{
                    data.filter((g) => g.isAgrupador == true)[0].submercado
                  }}</b>
              </div>
            </div>
            <div class="info-item">
              <div style="wisth: 33.33%" class="info-item-title">
                Tipo de Energia:
              </div>
              <div class="info-item-value">
                <b>{{
                    data.filter((g) => g.isAgrupador == true)[0].tipoEnergia
                  }}</b>
              </div>
            </div>
          </div>
        </div>
        <div v-if="data.length > 1" class="box-distribution-counter">
          <div class="counter-item"><b>Distribuição por Unidades</b></div>
          <div v-if="!isSummary" class="counter-item">
            <span class="mr-2">Volume a ser distribuído:</span>
            <b
            ><span
                :class="{
                  'color-red':
                    data.filter((g) => g.isAgrupador == true)[0].montanteNovo <
                    0,
                }"
            >
                {{
                data.filter((g) => g.isAgrupador == true)[0].montanteNovo
                    | volume
              }}
                MWh</span
            ></b
            >
          </div>
          <div style="min-width: 33%" v-if="!isSummary" class="counter-item">
            <span
                v-if="
                volumeRemains > 0 &&
                data.filter((g) => g.isAgrupador == true)[0].montanteReduzir
              "
                class="mr-2"
            >Falta distribuir:</span
            >
            <span
                v-if="
                volumeRemains < 0 &&
                data.filter((g) => g.isAgrupador == true)[0].montanteReduzir
              "
                class="mr-2"
            >Há volume excedente de:</span
            >
            <span
                v-if="
                volumeRemains != 0 &&
                data.filter((g) => g.isAgrupador == true)[0].montanteReduzir
              "
                style="color: #f7685b"
            >{{ volumeRemains | volume | positive }} MWh</span
            >
          </div>
        </div>

        <div class="grid-scroll mt-4">
          <div class="box-grid">
            <div class="grid-header">
              <div style="padding-left: 30px" class="grid-cell">CNPJ</div>
              <div class="grid-cell">Razão Social</div>
              <div style="text-align: center" class="grid-cell">Novo Preço</div>
              <div
                  v-if="data.length > 1"
                  style="text-align: center"
                  class="grid-cell"
              >
                Vol. Distribuído (MWh)
              </div>
              <div
                  v-if="data.length === 1"
                  style="text-align: center"
                  class="grid-cell"
              >
                Novo Montante (MWh)
              </div>
            </div>
            <div
                v-for="(contract, indexContract) in data.filter(
                (g) => g.isAgrupador == false
              )"
                :key="`indexContract${indexContract}`"
                class="grid-body"
                :class="{ 'contract-pai': contract.isAgrupador }"
            >
              <div style="padding-left: 30px" class="grid-cell">
                {{ contract.cnpj }}
              </div>
              <div class="grid-cell">{{ contract.razaoSocial }}</div>
              <div style="text-align: center" class="grid-cell">
                <span
                    :class="{
                    'color-red':
                      contract.precoNovo < 0 || contract.precoNovo === '',
                  }"
                >R$ {{ contract.precoNovo }} MWh
                </span>
              </div>
              <div style="text-align: center" class="grid-cell">
                <span v-if="isSummary">{{ contract.volumeDistribuido }}</span>
                <vue-numeric
                    v-if="!isSummary"
                    @input="calculateFilhoUser"
                    class="numeric-field-grid"
                    currency=""
                    :minus="false"
                    :precision="3"
                    separator="."
                    v-model="contract.volumeDistribuido"
                ></vue-numeric>
              </div>
            </div>
            <div v-if="data.length === 1">
              <div
                  v-for="(contract, indexContract) in data.filter(
                  (g) => g.isAgrupador == true
                )"
                  :key="`indexContract${indexContract}`"
                  class="grid-body"
              >
                <div style="padding-left: 30px" class="grid-cell">
                  {{ contract.cnpj }}
                </div>
                <div class="grid-cell">{{ contract.razaoSocial }}</div>
                <div style="text-align: center" class="grid-cell">
                  <span
                      :class="{
                      'color-red':
                        contract.precoNovo < 0 || contract.precoNovo === '',
                    }"
                  >R$ {{ contract.precoNovo }} MWh
                  </span>
                </div>
                <div style="text-align: center" class="grid-cell">
                  <span
                  ><b>{{ contract.montanteNovo | volume }}</b></span
                  >
                </div>
              </div>
            </div>
            <div v-if="data.length > 1" class="grid-total">
              <div style="padding-left: 30px" class="grid-cell">TOTAL</div>
              <div class="grid-cell"></div>
              <div class="grid-cell"></div>
              <div
                  style="padding-left: 5px; text-align: center"
                  class="grid-cell"
              >
                {{ total | volume }}
              </div>
            </div>
          </div>
        </div>
        <div class="box-buttons">
          <button @click="preview" class="mtx-btn btn-border mr-2">
            VOLTAR
          </button>
          <button
              :disabled="
              (volumeRemains != 0 && data.length > 1) ||
              (data.filter((g) => g.isAgrupador)[0].precoNovo &&
                !priceIsValid(data.filter((g) => g.isAgrupador)[0].precoNovo))
            "
              @click="next"
              style="width: 250px"
              class="mtx-btn btn-orange ml-2"
          >
            AVANÇAR
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import RepurchaseStatusService from "../../../services/repurchase-status";
////import HotsiteService from "../../../services/hotsite";
//import HostsiteRepurchaseService from "../../../services/hotsite-repurchase";
import ModalGetData from "../../ModalGetDataUser.vue";
import ModalProposalConfirm from "./ModalConfimationProposalNewave.vue";
//import Timmer from "../../../components/timmer/timmer.vue";
//import Filters from "../../../mtx-utils/MtxFilters.vue";
export default {
  //extends: Filters,
  props: ["id", "contrato"],
  data() {
    return {
      modalPrposalConfirmShow: false,
      modalGetDataUserShow: false,
      recompraMannual: false,
      spinner: false,
      total: 0,
      volumeRemains: 0,
      maximumPrice: 650,
      minimumPrice: 49.77,
      posvenda: "",
      data: [
        {
          isAgrupador: true,
          consumoApuradoTotal: 0,
          contrato: 0,
          precoOriginal: 0,
          takeMinimo: 0,
          cnpj: "",
          razaoSocial: "",
          submercado: "",
          tipoEnergia: "",
          spread: 0,
          pld: 0,
        },
      ],
      isSummary: false,
      isValid: true,
      isRateio: false,
    };
  },
  mounted() {
    this.getData();
    this.listPosVenda();
  },

  watch: {
    data: {
      handler: function (val, oldVal) {
        this.volumeRemains = (
            this.data.filter((g) => g.isAgrupador)[0].montanteNovo - this.total
        ).toFixed(3);
      },
      deep: true,
    },
  },

  methods: {
    timerfinished() {
      document.location.reload();
    },
    listPosVenda() {
      HostsiteRepurchaseService.getPosVenda({
        processId: this.id,
        contrato: this.contrato.wbcContract,
      }).then((response) => {
        this.posvenda = response;
      });
    },
    priceIsValid(value) {
      if (value > this.minimumPrice && value < this.maximumPrice) {
        return true;
      } else {
        return false;
      }
    },

    closeGetDataUser() {
      this.modalGetDataUserShow = false;
    },
    getUserResult(value) {
      this.spinner = false;
      this.modalGetDataUserShow = false;
      value.typeRecompra = "PRECO_UNICO";
      this.$emit("saveLogRecompra", value);
    },

    preview() {
      if (this.isSummary) {
        this.isSummary = false;
      } else {
        this.spinner = true;
        this.saveStatus("DEMONSTRATIVO");
      }
    },

    saveStatus(type) {
      RepurchaseStatusService.updateStatus({
        idProcess: parseInt(this.id),
        status: type,
      })
      .then(() => {
        if (type == "APROVADO") {
          document.location.reload();
        } else {
          document.location.reload();
        }
      })
      .catch((e) => {
        this.spinner = false;
      });
    },

    calculatePaiInicial() {
      this.data.forEach((contract) => {
        if (contract.isAgrupador) {
          let montanteReduzirInicial =
              contract.takeMinimo - contract.consumoApurado;
          let montanteNovo = contract.takeMinimo - montanteReduzirInicial;

          contract.notionalOriginal =
              contract.precoOriginal * contract.takeMinimo;
          contract.notionalRecompra =
              montanteReduzirInicial * contract.precoAjuste;
          contract.notionalFinal =
              contract.notionalOriginal - contract.notionalRecompra;
          let precoNovoInicial = contract.notionalFinal / montanteNovo;

          if (this.priceIsValid(precoNovoInicial)) {
            contract.montanteReduzir = montanteReduzirInicial.toFixed(3);
            contract.precoNovo = precoNovoInicial;
            contract.montanteNovo = montanteNovo;
          } else {
          }
        }
      });

      if (this.isRateio) {
        this.calculateFilhoInicial();
      }
      this.$forceUpdate();
    },

    calculatePaibyUser() {
      this.data.forEach((contract) => {
        if (contract.isAgrupador) {
          if (contract.montanteReduzir == "") {
            contract.precoNovo = "";
            contract.montanteNovo = "";
          } else {
            contract.montanteNovo =
                contract.takeMinimo -
                parseFloat(contract.montanteReduzir).toFixed(3);
            contract.notionalOriginal =
                contract.precoOriginal * contract.takeMinimo;
            contract.notionalRecompra =
                parseFloat(contract.montanteReduzir).toFixed(3) *
                contract.precoAjuste;
            contract.notionalFinal =
                contract.notionalOriginal - contract.notionalRecompra;
            contract.precoNovo = contract.notionalFinal / contract.montanteNovo;
          }
        }
      });
      this.$forceUpdate();

      if (this.isRateio) {
        //this.calculateFilho();
      }
    },

    calculateFilhoInicial() {
      this.data.forEach((contract) => {
        if (!contract.isAgrupador) {
          contract.volumeDistribuido =
              (contract.consumoApurado /
                  this.data.filter((g) => g.isAgrupador == true)[0]
                      .consumoApurado) *
              this.data.filter((g) => g.isAgrupador == true)[0].montanteNovo;
          contract.precoNovo = this.data.filter(
              (g) => g.isAgrupador == true
          )[0].precoNovo;
        }
      });

      this.distributedTotalCalculation();
    },

    calculateFilhoUser() {
      this.data.forEach((contract) => {
        if (!contract.isAgrupador) {
          contract.precoNovo = this.data.filter(
              (g) => g.isAgrupador == true
          )[0].precoNovo;
        }
      });

      this.distributedTotalCalculation();
    },

    formatMontanteReduzir() {
      let value = this.data.filter((g) => g.isAgrupador)[0].montanteReduzir;
      if (value < 0) {
        value = value * -1;
      }
      this.data.filter((g) => g.isAgrupador)[0].montanteReduzir =
          parseFloat(value).toFixed(3);
      this.calculatePaibyUser();
    },

    changeMontanteReduzir() {
      this.calculatePaibyUser();
    },

    distributedTotalCalculation() {
      this.total = 0;
      this.data.forEach((contract) => {
        if (!contract.isAgrupador) {
          this.total = this.total + contract.volumeDistribuido;
        }
      });
      this.total = this.total.toFixed(3);
    },

    getData() {
      (this.spinner = true),
          HostsiteRepurchaseService.getData({ processId: this.id })
          .then((response) => {
            let requestPost = response.map((g) => {
              g.precoAjuste = g.pld + g.spread;
              g.isPrecoUnico = true;
              g.notionalFinal = 0;
              g.notionalOriginal = 0;
              g.notionalRecompra = 0;
              g.precoNovo = 0;
              g.montanteReduzir = "";
              g.volumeDistribuido = 0;
              g.montanteNovo = 0;
              return g;
            });
            this.data = requestPost.reverse();

            if (response.length > 1) {
              this.isRateio = true;
            }
            this.calculatePaiInicial();
            this.spinner = false;
          })
          .catch((e) => {
            this.spinner = false;
          });
    },

    formatVolume(value) {
      if (!value || isNaN(value) || !isFinite(value)) {
        return "0,000";
      }
      var m = parseFloat(value).toFixed(3);
      m = m.toString().replace(".", ",");
      var parts = m.split(",");
      if (parts[0].toString().length > 3) {
        parts[0] = [parts[0].slice(0, -3), ".", parts[0].slice(-3)].join("");
      }
      let result = parts[0] + "," + parts[1];
      return result.replace("-.", "-");
    },

    next() {
      this.spinner = true;

      this.data.forEach((contract) => {
        if (contract.isAgrupador == false) {
          contract.montanteNovo = contract.volumeDistribuido;
        }
      });
      if (
          this.priceIsValid(
              this.data.filter((g) => g.isAgrupador == true)[0].precoNovo
          ) < 0
      ) {
        this.mxShowModalAlert({
          title: "Atenção",
          message: "Novo preço não pode ser negativo",
          type: "confirm",
        }).then(() => {});
      } else {
        HotsiteService.getBilling({ processId: this.id }).then((response) => {
          //response.statusPrice = "expirado"
          this.spinner = false;
          if (
              !this.priceIsValid(
                  this.data.filter((g) => g.isAgrupador == true)[0].precoNovo
              ) ||
              response.statusPrice != "APROVADO"
          ) {
            let html = `Devido à condição de mercado,
        essa proposta de redução de montantes será tratada por
        seu executivo de relacionamento.<br>
        <b>Deseja continuar assim ?</b>
 `;
            this.mxShowModalAlert({
              title: "Atenção",
              message: html,
              type: "cancelar",
            })
            .then(() => {
              this.recompraMannual = true;
              this.modalGetDataUserShow = true;
            })
            .catch((e) => {});
          } else {
            if (
                this.data.filter((g) => g.isAgrupador == true)[0].montanteNovo <
                0.1
            ) {
              this.mxShowModalAlert({
                title: "Atenção",
                message: "Novo Montante deve ser maior que <b>0,1 MWh</b>",
                type: "confirm",
              }).then(() => {});
            } else {
              if (
                  this.data.filter((g) => g.isAgrupador == true)[0]
                      .montanteReduzir == 0
              ) {
                this.mxShowModalAlert({
                  title: "Atenção",
                  message: "Montante a Reduzir está zerado",
                  type: "confirm",
                });
              } else {
                this.spinner = false;
                this.modalGetDataUserShow = true;
              }
            }
          }

          //
        });
      }
    },

    contestForValue(value) {
      this.modalGetDataUserShow = false;

      this.spinner = true;
      let requestConfirm = {
        id: 0,
        idProc: this.id,
        justify: "",
        status: "RECOMPRA_MANUAL", //status: "REPROVADO",
      };

      HotsiteService.updateStatus(requestConfirm)
      .then(() => {
        let statusRequest = {
          idProcess: this.id,
          status: "RECOMPRA_MANUAL",
        };

        RepurchaseStatusService.updateStatus(statusRequest)
        .then(() => {
          let requestAproval = {
            createdAt: null,
            email: value.infos.email,
            id: 0,
            name: value.infos.name,
            processId: this.id,
            type: "RC",
          };
          HotsiteService.logAproval(requestAproval).then((response) => {
            this.$emit("completeTask");
          });

          c;
        })
        .catch((e) => {});
        this.spinner = false;
      })
      .catch(() => {
        this.spinner = false;
      });
    },

    post() {
      this.spinner = true;

      HostsiteRepurchaseService.saveCalculation(this.data, this.id)
      .then((response) => {
        this.$emit("finalizarRecompra", "PRECO_UNICO");
        this.spinner = false;
      })
      .catch((e) => {
        this.spinner = false;
      });
    },

    modalaproved() {
      this.modalPrposalConfirmShow = true;
    },

    nextConfirmModal() {
      this.spinner = false;
      this.modalPrposalConfirmShow = false;
      this.saveStatus("APROVADO");
    },
  },
  components: {
    ModalGetData,
    ModalProposalConfirm,
    //Timmer,
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(117.6vh - 86px);
}
.breadcrumbs {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  background: #ffffff;
  border-radius: 5px;
  width: 80%;
  display: flex;
  justify-items: center;
  padding: 5px 0 5px 20px;
  margin-top: 30px;
  margin-bottom: 39px;
  flex-wrap: wrap;
}
.alert-msg {
  width: 100%;
  background: #f8f8f8;
  border-radius: 5px;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #505050;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.alert-icon {
  padding: 0 10px 0 10px;
  img {
    width: 20px;
    margin-top: -2px;
  }
}
.box-reduction-amount {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 80%;
}
.box-head {
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #929292;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 0 8px 20px;
  display: flex;
  align-items: center;
}
.box-content {
  padding: 20px;
}

.box-infos {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 27px 0px 27px 0px;
}
.info-row {
  display: flex;
  justify-content: flex-start;
  @include tablet {
    flex-direction: column;
  }
}
.info-item {
  width: 25%;
  padding-left: 70px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  @include tablet {
    width: 100%;
    text-align: center;
    padding-left: 0px;
    margin-bottom: 20px;
  }
}
.item-consumo {
  width: 50%;
  @include tablet {
    width: 100%;
  }
}
.item-razaoSocial {
  width: 75%;
  @include tablet {
    width: 100%;
  }
}
.info-item-title {
  margin-bottom: 2px;
}
.info-item-value {
  margin-top: 2px;
}
.numeric-field-grid {
  background: #ebebeb;
  border-radius: 3px;
  border: none;
  width: 100px;
  text-align: center;
  &:disabled {
    background: #c4c4c4;
    color: #c4c4c4;
  }
}
.box-distribution-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #505050;
  margin-top: 41px;
  margin-bottom: 25px;
  @include tablet {
    flex-direction: column;
  }
}
.counter-item {
  @include tablet {
    margin-bottom: 10px;
  }
}
.grid-scroll {
  overflow: auto;
}
.box-grid {
  min-width: 800px;
  max-height: 400px;
}
.grid-header {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #000000;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #929292;
  padding: 7px 0 7px 0;
}
.grid-body {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #505050;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 7px 0 7px 0;
}
.grid-total {
  display: flex;
  justify-content: space-between;
  background: #e8e8e8;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #505050;
  padding: 7px 0 7px 0;
}
.grid-cell {
  width: 25%;
  padding-right: 30px;
}
.box-buttons {
  margin-top: 31px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include tablet {
    justify-content: center;
  }
}
.btn-orange {
  width: 174px;
  height: 42px;
  background: #0b2b40;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background: rgba($color: #0b2b40, $alpha: 0.8);
    color: #fff;
  }
  &:disabled {
    background: rgba($color: #ccc, $alpha: 0.8);
    cursor: not-allowed;
  }
}
.btn-border {
  width: 174px;
  height: 42px;
  border: 1px solid #505050;
  box-sizing: border-box;
  border-radius: 4px;
  background: #fff;
}
.color-red {
  color: red;
  font-weight: bold;
}

.contract-pai {
  background: #eee;
}
.color-crump {
  color: #0b2b40;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.item-timer {
  align-self: flex-end;
}
.text-timer {
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #505050;
  padding: 7px 0 7px 0;
}
</style>
