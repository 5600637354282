<template>
  <div
    class="modal"
    tabindex="-1"
    id="warningModal"
    @click.self="
      (e) => (rejectOnCloseModal ? resultReject() : preventPropagation(e))
    "
    data-cy="div-warning_modal-warning-modal"
  >
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100 text-center">
            <strong>{{ title }}</strong>
          </h5>
          <button
            type="button"
            aria-label="Close"
            :class="['btn-close']"
            data-bs-dismiss="modal"
            @click="
              rejectOnCloseModal || rejectOnCloseIcon ? resultReject() : null
            "
            data-cy="button-fechar_warning_modal-warning-modal"
          ></button>
        </div>
        <div class="modal-body text-break" v-html="message"></div>
        <div class="modal-footer d-flex align-items-center justify-content-end">
          <button
            type="button"
            v-if="showBtnFalse"
            @click="resultReject()"
            data-bs-dismiss="modal"
            :class="['btn', btnSecondary, 'buttons']"
            data-cy="button-botao_false-warning-modal"
          >
            {{ btnFalse }}
          </button>
          <button
            type="button"
            data-bs-dismiss="modal"
            @click="resultResolve()"
            :class="['btn', btnPrimary, 'buttons']"
            data-cy="button-botao_true-warning-modal"
          >
            {{ btnTrue }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Modal } from "bootstrap";
const store = {
  dialog: {},
};
export default {
  data() {
    return {
      btnTrue: "Sim",
      btnFalse: "Não",
      title: "Atenção",
      showBtnFalse: true,
      resultReject: undefined,
      rejectOnCloseIcon: false,
      resultResolve: undefined,
      rejectOnCloseModal: false,
      message: "Ocorreu um erro ao realizar esta operação",
    };
  },
  mounted() {
    store.dialog = this;
  },
  computed: {
    btnPrimary(){
      return this.$route.path.includes("newave")
        ? "btn-primary-full-filled-newave"
        : "btn-primary-full-filled";
    },
    btnSecondary(){
      return this.$route.path.includes("newave")
        ? "btn-secondary-full-filled-newave"
        : "btn-secondary-full-filled";
    },
  },
  methods: {
    preventPropagation(e) {
      e.stopImmediatePropagation();
    },
  },
  show({
    title,
    message,
    btnTrue,
    btnFalse,
    showBtnFalse,
    rejectOnCloseIcon,
    rejectOnCloseModal,
  }) {
    return new Promise((resolve, reject) => {
      store.dialog.title = title;
      store.dialog.message = message;
      store.dialog.btnTrue = btnTrue;
      store.dialog.btnFalse = btnFalse;
      store.dialog.showBtnFalse = showBtnFalse;
      store.dialog.rejectOnCloseIcon = rejectOnCloseIcon;
      store.dialog.rejectOnCloseModal = rejectOnCloseModal;
      store.dialog.resultResolve = resolve;
      store.dialog.resultReject = reject;
      const modal = new Modal(document.getElementById("warningModal"), {});
      modal.show();
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  outline: 0;
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 20px;
  pointer-events: auto;
  flex-direction: column;
  background-clip: padding-box;
  background-color: #fffaf8;
}

.modal-dialog {
  border-radius: 60%;
}
</style>
