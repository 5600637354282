var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(1 == 2)?_c('div',{staticClass:"box-take mb-3"},[_vm._m(0)]):_vm._e(),(
      _vm.contratoPrincipal.flexibilidade &&
      _vm.contratoPrincipal.flexibilidade.take === 'Acima' &&
      _vm.contratoPrincipal.parametrizacao.recebeCurtoPrazo &&
      !_vm.curtoPrazo.id &&
      _vm.demonstrativo.situacaoDemonstrativo.situacao === 'APROVADO'
    )?_c('div',{staticClass:"box-alert-short-term-proposal",staticStyle:{"border":"#ffb946 solid 1px"}},[_c('div',{staticClass:"box-alert-logo"},[_c('img',{attrs:{"src":_vm.mountImg('/images/icon-alert-triangulo.svg')}})]),_c('div',{staticClass:"box-alert-text"},[_c('b',[_vm._v("IMPORTANTE: ")]),_vm._v(": Preparamos uma "),_c('b',[_vm._v("Proposta de Curto Prazo")]),_vm._v(" referente ao "),_c('b',[_vm._v("volume excedente")]),_vm._v(" consumido neste mês. Você poderá recebê-la "),_c('span',{staticClass:"pointer",staticStyle:{"color":"#89D99C","cursor":"pointer"},on:{"click":_vm.solicitarPropostaCurtoPrazo}},[_c('b',[_vm._v("clicando aqui")])]),_vm._v(". ")])]):_vm._e(),(
      _vm.contratoPrincipal.flexibilidade &&
      _vm.contratoPrincipal.flexibilidade.take === 'Acima' &&
      _vm.contratoPrincipal.parametrizacao.recebeCurtoPrazo &&
      _vm.demonstrativo.situacaoDemonstrativo.situacao !== 'APROVADO' &&
      _vm.demonstrativo.situacaoDemonstrativo.situacao !== 'REPROVADO'
    )?_c('div',{staticClass:"box-alert-short-term-proposal"},[_c('div',{},[_c('img',{attrs:{"src":_vm.mountImg('/images/icon-alert-triangulo.svg')}})]),_c('div',{staticClass:"box-alert-text"},[_vm._v(" Apresentamos abaixo o demonstrativo de faturamento do contrato "),_c('b',[_vm._v(_vm._s(_vm.contratoPrincipal.codigo))]),_vm._v(", cujo o volume utilizado este mês "),_c('b',[_vm._v("ultrapassou "),(_vm.curtoPrazo.isFatorDeAtendimentoIntegral)?_c('span',[_vm._v(" em "+_vm._s(_vm._f("volume")(_vm.DIFERENCA))+" MWh ")]):_vm._e(),_vm._v(" o limite máximo contratado")]),_vm._v(" (Take Máximo). "),_c('u',[_vm._v("O faturamento será realizado pelo take máximo contratado")]),_vm._v(". Preparamos uma "),_c('b',[_vm._v("Proposta de Curto Prazo")]),_vm._v(" referente ao excedente consumido neste mês. Você poderá acessá-la após a análise e aprovação do demonstrativo de faturamento abaixo: ")])]):_vm._e(),(
      _vm.demonstrativo.situacaoDemonstrativo.situacao == 'APROVADO' &&
      _vm.curtoPrazo &&
      _vm.curtoPrazo.status == 'AGUARDANDO_PRECO'
    )?_c('div',{staticClass:"box-messages-short-term"},[_c('div',{staticClass:"box-messages-short-term-icon"},[_c('img',{attrs:{"src":_vm.mountImg('/images/icon-alert-message.svg')}})]),_vm._m(1)]):_vm._e(),(_vm.curtoPrazo && _vm.curtoPrazo.status == 'EM_PREENCHIMENTO')?_c('div',{staticClass:"box-messages-short-term"},[_c('div',{staticClass:"box-messages-short-term-icon"},[_c('img',{attrs:{"src":_vm.mountImg('/images/icon-alert-message.svg')}})]),_c('div',{staticClass:"box-messages-short-term-message"},[_c('span',[_vm._v("Proposta de Curto Prazo enviada para os contatos:")]),_vm._l((_vm.curtoPrazo.emailsEnviados),function(contato,indexContato){return _c('span',{key:("indexContato" + indexContato),staticStyle:{"font-size":"14px"}},[_vm._v(" | (Email : "+_vm._s(contato)+") ")])})],2)]):_vm._e(),(_vm.curtoPrazo.status === 'APROVADO')?_c('div',{staticClass:"box-messages-short-term"},[_c('div',{staticClass:"box-messages-short-term-icon"},[_c('img',{attrs:{"src":_vm.mountImg('/images/icon-alert-triangulo.svg')}})]),_vm._m(2)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(" O "),_c('b',[_vm._v("Take Máximo")]),_vm._v(" deste contrato foi atingido neste faturamento, segue demonstrativo da medição e dos limites contratuais abaixo: ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-messages-short-term-message"},[_c('span',[_vm._v(" Sua solicitação foi registrada, e sua proposta será enviada em breve. Caso esse pedido tenha sido realizado fora do horário comercial o envio ocorrera no próximo dia útil. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-messages-short-term-message"},[_c('span',[_vm._v(" FOI GERADA E APROVADA UMA PROPOSTA DE CURTO PRAZO. CONSULTE O E-MAIL.")])])}]

export { render, staticRenderFns }