import { loginRedirect } from "@/utils/authentication";
import { userHasPermissions } from "@/utils/permissions";
import { Buffer } from "buffer";

export default function beforeach(router) {
  router.beforeEach((to, from, next) => {
    const token = window.sessionStorage.getItem(process.env.VUE_APP_TOKEN_KEY);
    const pKeys = window.sessionStorage.getItem("p-keys");

    const defaultTitle = "Billing Matrix";
    document.title = to.meta.title || defaultTitle;
    next();

    const favicon = document.getElementById("icon");
    if (to.meta.title === "Newave") {
      favicon.href = "/newave/favicon.newave.svg";
    } else {
      favicon.href = "/favicon.ico";
    }

    if (!to.path.includes("/faturamento/empresa")) {
      window.sessionStorage.removeItem("c-key");
    }

    if (!to.matched.some((route) => !route.meta.public)) {
      return next();
    }
    if (!token) {
      return loginRedirect();
    }
    if (!pKeys || !to.meta.permissions?.length) return next();

    const perms = JSON.parse(Buffer.from(pKeys, "base64").toString("utf8"));

    if (!userHasPermissions(perms, to.meta.permissions)) {
      return next();
    }
    if (from.path !== "/") {
      return next({ path: from.path });
    }

    const routes = {
      "/faturamento": ["VISUALIZAR_FATURAMENTO", "REALIZAR_FATURAMENTO"],
      "/pld-spread": [
        "AJUSTES_PLD_SPREAD_VISUALIZAR",
        "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR",
        "AJUSTES_PLD_SPREAD_VISUALIZAR_EDITAR_APROVAR",
      ],
      "/pontos-medicao": [
        "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR",
        "ASSOCIAR_PONTO_AO_CONTRATO_VISUALIZAR_EDITAR",
      ],
      "/proinfa": [
        "CONFIGURAR_PROINFA_VISUALIZAR",
        "CONFIGURAR_PROINFA_VISUALIZAR_EDITAR",
      ],
      "/pos-venda": [
        "ASSOCIAR_POS_VENDA_VISUALIZAR",
        "ASSOCIAR_POS_VENDA_VISUALIZAR_ASSOCIAR",
      ],
      "/exportador": [
        "EXPORTADOR_MONTANTES_VISUALIZAR",
        "EXPORTADOR_MONTANTES_VISUALIZAR_EXPORTAR",
        "EXPORTADOR_ENCARGOS_CCEE_VISUALIZAR",
        "EXPORTADOR_ENCARGOS_CCEE_VISUALIZAR_EDITAR",
      ],
      "/nao-importados": ["CONTRATOS_NAO_IMPORTADOS_HABILITADO"],
      "/parametrizacao-home": [
        "ALCADAS_APROVACAO_VISUALIZAR",
        "ALCADAS_APROVACAO_VISUALIZAR_EDITAR",
        "CURTO_PRAZO_VISUALIZAR",
        "CURTO_PRAZO_VISUALIZAR_EDITAR",
        "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR",
        "ROTINA_IMPORTACAO_VISUALIZAR_ATIVAR_EDITAR",
        "FECHAR_FATURAMENTO_HABILITADO",
      ],
      "/dashboards": [
        "EMBEDDED BILLING-FINANCEIRO",
        "EMBEDDED BILLING-MONITOR GERAL",
        "EMBEDDED BILLING-MONITOR CONTAGEM",
        "EMBEDDED BILLING-MONITOR MWM",
        "EMBEDDED BILLING-MONITOR FINANCEIRO",
        "EMBEDDED BILLING-PORTFÓLIO",
        "EMBEDDED BILLING-POS VENDAS",
        "EMBEDDED BILLING-FINANCEIRO - B2",
        "EMBEDDED BILLING-MONITOR CONTAGEM - B2",
        "EMBEDDED BILLING-MONITOR GERAL - B2",
        "EMBEDDED BILLING-MONITOR MWM - B2",
        "EMBEDDED BILLING-PORTFÓLIO - B2",
        "EMBEDDED BILLING-POS VENDAS - B2",
        "EMBEDDED BILLING-MONITOR FINANCEIRO - B2",
      ],
    };

    const routeKeys = Object.keys(routes);
    for (let i = 0; i < routeKeys.length; i++) {
      const pathRequiredPerms = routes[routeKeys[i]];
      for (let j = 0; j < pathRequiredPerms.length; j++) {
        if (userPermissions.includes(pathRequiredPerms[j])) {
          return next({
            path: routeKeys[i],
          });
        }
      }
    }

    return next();
  });
}
