<template>
  <div class="page">
    <center>
      <div class="box-choose-peoposal">
        <div class="box-header-proposal">PROPOSTA DE REDUÇÃO DE MONTANTES</div>
        <div class="box-content">
          <div class="content-icon">
            <img :src="mountImg('/images/mtx/tele.svg')" />
          </div>
          <div class="content-text pb-5">
            <b>ATENÇÃO: </b> Devido às condições de mercado essa proposta foi
            encaminhada diretamente para seu Executivo de Relacionamento.
          </div>
        </div>
      </div>
    </center>
  </div>
</template>
<script>
export default {
  props: ["id", "contrato"],
  data() {
    return {
      posvenda: "",
    };
  },
  mounted() {
    this.listPosVenda();
  },
  methods: {
    chooseOption(option) {
      this.$emit("optionProposedReduction", option);
    },

    listPosVenda() {
      HostsiteRepurchaseService.getPosVenda({
        processId: this.id,
        contrato: this.contrato.wbcContract,
      }).then((response) => {
        this.posvenda = response;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 100%;
  height: calc(117.6vh - 116px);
}
.box-choose-peoposal {
  background: #ffffff;
  width: 70%;
  margin-top: 30px;
}
.box-header-proposal {
  display: flex;
  align-items: center;
  height: 40px;
  background: #929292;
  border-radius: 4px 4px 0px 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  padding-left: 20px;
}
.box-content {
  display: flex;
}
.content-icon {
  padding: 36px 20px 20px 20px;
}
.content-text {
  text-align: left;
  padding: 41px 10px 0px 0;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #505050;
}
.box-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 52px 0 52px 0;
  margin-left: -30px;
}
.btn {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  text-align: center;
  letter-spacing: 0.01em;
  background: #0b2b40;
  border-radius: 4px;
  color: #fff;
  padding: 8px 20px 8px 30px;
  &:hover {
    background: rgba($color: #0b2b40, $alpha: 0.8);
    color: #fff;
  }
}
</style>