import AccessDenied from "@/pages/AccessDenied.vue";
import Home from "@/pages/Home.vue";
import Login from "@/pages/Login.vue";
import LoginCallback from "@/pages/LoginCallback.vue";
import NotFound from "@/pages/NotFound.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import Demonstrativo from "../pages/hot-site/Demonstrativo.vue";
import DemonstrativoNewave from "../pages/hot-site/newave/DemonstrativoNewave.vue";
import DemonstrativoDG from "../pages/hot-site/DemonstrativoDG.vue";
import PropostaCurtoPrazo from "../pages/hot-site/curto-prazo/PropostaCurtoPrazo.vue";
import PropostaCurtoPrazoNewave from "@/pages/hot-site/newave/curto-prazo/PropostaCurtoPrazoNewave.vue";
import FaturamentoEmpresaTemplate from "../pages/templates-faturamento/EmpresaTemplate.vue";
import beforeEach from "./before-each";
import MenuNavigationRoutes from "./menu-navigation";
import StatusTemplateRoutes from "./status-templates";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      public: true,
    },
  },
  {
    path: "/faturamento",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/faturamento/empresa/:empresaId/:contratoId",
        name: "Faturamento template",
        component: FaturamentoEmpresaTemplate,
      },
      ...MenuNavigationRoutes,
      ...StatusTemplateRoutes,
    ],
  },
  {
    path: "/demonstrativo/detalhamento",
    name: "Demonstrativo",
    component: Demonstrativo,
    meta: {
      public: true,
    },
  },
  {
    path: "/demonstrativo/detalhamento/newave",
    name: "DemonstrativoNewave",
    component: DemonstrativoNewave,
    meta: {
      title: "Newave",
      public: true,
    },
  },
  {
    path: "/demonstrativo/desconto-garantido/detalhamento",
    name: "DemonstrativoDG",
    component: DemonstrativoDG,
    meta: {
      public: true,
    },
  },
  {
    path: "/demonstrativo/",
    name: "Proposta Curto Prazo",
    component: PropostaCurtoPrazo,
    meta: {
      public: true,
    },
  },
  {
    path: "/demonstrativo/newave",
    name: "Proposta Curto Prazo Newave",
    component: PropostaCurtoPrazoNewave,
    meta: {
      title: "Newave",
      public: true,
    },
  },
  {
    path: "/login/callback",
    name: "LoginCallback",
    component: LoginCallback,
    meta: {
      public: true,
    },
  },
  {
    path: "/login",
    alias: ["/login/signout"],
    name: "Login",
    component: Login,
    meta: {
      public: true,
    },
  },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: AccessDenied,
    meta: {
      public: true,
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: {
      public: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

beforeEach(router);

export default router;
