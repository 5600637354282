<template>
  <div>
    <div :style="mxIsChromeBrowser() ? 'height: 117.6vh' : 'height: 100vh'">
      <div class="__header">
        <div class="header__box-logo">
          <img src="@/assets/logo/logo-newave.png" />
        </div>
        <div class="text__header">Portal Newave</div>
      </div>

      <div
        class="box-expirado"
        :style="mxIsChromeBrowser() && 'height: calc(117.6vh - 60px)'"
      >
        <div
          class="text-expirado"
          v-if="demonstrativo.situacaoDemonstrativo.situacao === 'APROVADO'"
        >
          O demonstrativo de faturamento já foi finalizado e aprovado, para
          realizar o download do PDF de versão final, clique no botão abaixo.
        </div>

        <div class="text-expirado" v-if="demonstrativo.spreadExpirado">
          O demonstrativo está expirado. Favor entrar em contato com seu officer
          pós-vendas
        </div>
        <div
          class="text-expirado"
          v-else-if="
            demonstrativo.situacaoDemonstrativo.situacao !== 'APROVADO' &&
            demonstrativo.situacaoDemonstrativo.situacao !== 'REPROVADO'
          "
        >
          Favor entrar em contato com seu officer pós-vendas
        </div>

        <div class="box-pdf">
          <div class="btn-pdf" @click="solicitarPdfDemonstrativo">
            <span class="icon-pdf">
              <img :src="mountImg('/images/pdf.svg')" />
            </span>
            Visualizar Demonstrativo de faturamento em PDF
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TypesToastENUM from "@/enums/TypesToastENUM";
import DemonstrativoService from "@/services/DemonstrativoService";

export default {
  props: {
    demonstrativo: Object,
    contrato: Object,
  },
  data() {
    return {
      idFaturamentoPDF: undefined,
    };
  },
  mounted() {
    const { contratoId } = JSON.parse(window.atob(this.$route.query.code));
    this.idFaturamentoPDF = contratoId;
  },
  methods: {
    solicitarPdfDemonstrativo() {
      if (!this.idFaturamentoPDF) {
        this.mxToggleToast({
          title: "Erro",
          message: "ID do faturamento não encontrado.",
          type: TypesToastENUM.DANGER,
        });
        return;
      }
      const msgInfo =
        "Estamos processando o seu demonstrativo. O download iniciará em breve";
      this.mxToggleToast({
        title: "Info",
        message: msgInfo,
        type: TypesToastENUM.INFO,
        delay: 2000,
      });
      DemonstrativoService.getPdfDemonstrativo(this.idFaturamentoPDF, {
        responseType: "blob",
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `demonstrativo.pdf`;

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          console.error("Erro ao baixar o PDF:", err);
          let msgError = "Ocorreu um erro ao processar o demonstrativo";
          setTimeout(() => {
            this.mxToggleToast({
              title: "Erro",
              message: msgError,
              type: TypesToastENUM.DANGER,
            });
          }, 5000);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.text-expirado {
  max-width: 800px;
  padding: 0 30px;
  font-size: 1.5rem;
}
.__header {
  height: 60px;
  width: 100%;
  display: flex;
  background: #0b2b40;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #ffffff;

  img {
    width: 45px;
  }
}

.header__box-logo {
  padding: 0 15px 0 15px;
}
.box-expirado {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: #0b2b40;
  margin-bottom: 20px;
}
.box-pdf {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-pdf {
  background: #0b2b40;
  color: #fff;
  border-radius: 4px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px 70px 5px 70px;
  margin-bottom: 60px;
  cursor: pointer;
}

.icon-pdf {
  margin: -5px 15px 0 0px;
}
</style>
