import {
    EMPTY,
    realSymbolFormatter,
    roundFormatter,
    volumeFormatter,
  } from "@/filters/formatters";
  import ExportadorModel from "@/models/ExportadorModel";
  import { padLeft } from "@/utils/functionsUtils";
  
  export default class TableExportadorEncargosCCEEModel extends ExportadorModel {
    constructor(args) {
      super({ ...args, tipo: "encargos" });
    }
  
    static endpoint = "/encargos";
  
  
    static getTableFields() {
      return [
        {
          name: "ano",
          title: "Ano",
          formatter(value) {
            return EMPTY(value);
          },
        },
        {
          name: "mes",
          title: "Mês",
          formatter(value) {
            return EMPTY(padLeft(value));
          },
        },
        {
          name: "apelido",
          title: "Apelido",
          formatter(value) {
            return EMPTY(value);
          },
        },
        {
          name: "contrato",
          title: "Contrato",
          formatter(value) {
            return EMPTY(value);
          },
        },
        {
          name: "pontoMedicao",
          title: "Ponto de medição",
          formatter(value) {
            return EMPTY(value);
          },
        },
      {
        name: "montanteBilling",
        title: "Montante Billing",
        getValue(row) {
          return volumeFormatter(row._montanteBilling?.volumeAFaturar);
        },
      },
        {
          name: "eer",
          title: "E.E.R",
          formatter: (value) => {
          return realSymbolFormatter(roundFormatter(value, 2));
        },
        },
        {
          name: "ercap",
          title: "E.R.C.A.P",
          formatter: (value) => {
            return realSymbolFormatter(roundFormatter(value, 2));
          },
        },
        {
          name: "ess",
          title: "E.S.S",
          formatter: (value) => {
            return realSymbolFormatter(roundFormatter(value, 2));
          },
        },
        {
          name: "selecionar",
          title: "Selecionar",
          align: "center",
        },
      ];
    }
  }