<template>
  <div>
    <ModalBase v-if="showModalRedistribuir" @closeModal="close">
      <template #header> REDISTRIBUIR VOLUMES </template>
      <template #body>
        <redistribute
          :contratosFilhos="CONTRATOS_FILHOS"
          :mainContrato="mainContrato"
          @closeModal="close"
        />
      </template>
    </ModalBase>

    <div class="box-units">
      <div class="units-header">
        <div class="_box_title">Distribuição por unidades</div>

        <button
          class="btn-action-primary-newave"
          style="max-width: 300px"
          @click="showModalRedistribuir = true"
          v-if="
            mainContrato.contratosFilhos &&
            mainContrato.contratosFilhos.length > 0 &&
            demonstrativo.situacaoDemonstrativo.situacao !== 'APROVADO' &&
            demonstrativo.situacaoDemonstrativo.situacao !== 'REPROVADO'
          "
        >
          REDISTRIBUIR VOLUME
        </button>
      </div>

      <div class="unit-scroll">
        <table class="table-unit">
          <thead>
            <tr>
              <th style="padding-left: 20px">Controle</th>
              <th class="cnpj">CNPJ</th>
              <th>P. Medição</th>
              <th>Submercado</th>
              <th>Montante SCDE (MWh) </th>
              <th>Perdas (%)</th>
              <th>Proinfa (MWh)</th>
              <th>F. Atend. (%)</th>
              <th>Cons. Apurado (MWh)</th>
              <th>Vol. Faturar (MWh)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(contrato, indexContract) in CONTRATOS_FILHOS"
              :key="`indexContract${indexContract}`"
              class="rows-table"
            >
              <td style="padding-left: 20px">{{ contrato.codigo }}</td>
              <td class="cnpj">
                {{ contrato.empresa.cnpj | cnpj }}
              </td>
              <td class="">
                {{
                  contrato.pontoMedicao ? contrato.pontoMedicao.codigo : "--"
                }}
              </td>
              <td class="">
                {{ contrato.submercado ? contrato.submercado.descricao : "--" }}
              </td>
              <td class="">
                {{
                  contrato.calculoFaturamento &&
                  contrato.calculoFaturamento.apuracao
                    ? contrato.calculoFaturamento.apuracao
                        .quantidadeMontanteScde
                    : 0 | volume
                }}
              </td>
              <td class="">{{ contrato.percentualPerda | moeda }}%</td>

              <td class="">
                {{
                  contrato.pontoMedicao && contrato.pontoMedicao.proinfa
                    ? contrato.pontoMedicao.proinfa.valor
                    : "--" | volume
                }}
              </td>

              <td class="">
                <span v-if="contrato.percentualFatorAtendimento == 100">{{
                  contrato.percentualFatorAtendimento
                }}</span>
                <span v-else>{{
                  contrato.percentualFatorAtendimento | volume
                }}</span>
                %
              </td>
              <td style="font-weight: 600">
                {{
                  contrato.calculoFaturamento
                    ? contrato.calculoFaturamento.volumeBruto
                    : 0 | volume
                }}
              </td>
              <td style="font-weight: 600">
                {{
                  contrato.calculoFaturamento
                    ? contrato.calculoFaturamento.volumeAFaturar
                    : 0 | volume
                }}
              </td>
            </tr>
            <tr></tr>

            <tr
              class="rows-table rows-total"
              v-if="CONTRATOS_FILHOS.length > 0"
            >
              <td>TOTAL</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>{{ TOTAL_VOLUME_SCDE | volume }}</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>{{ TOTAL_CONSUMO_APURADO | volume }}</td>
              <td>{{ TOTAL_VOLUME_FATURAR | volume }}</td>
            </tr>

            <tr
              v-if="mainContrato.tipoClasse == 'NAO_RATEIO'"
              class="rows-table"
            >
              <td>{{ mainContrato.codigo }}</td>
              <td>{{ mainContrato.empresa.cnpj | cnpj }}</td>
              <td>
                {{
                  mainContrato.pontoMedicao
                    ? mainContrato.pontoMedicao.codigo
                    : "--"
                }}
              </td>
              <td>{{ mainContrato.submercado.descricao }}</td>
              <td>
                {{
                  mainContrato.calculoSumarizado
                    ? mainContrato.calculoSumarizado.montanteScde
                    : 0 | volume
                }}
              </td>
              <td>{{ mainContrato.percentualPerda }}%</td>
              <td>
                {{
                  mainContrato.pontoMedicao
                    ? mainContrato.pontoMedicao.proinfa.valor
                    : 0 | volume
                }}
              </td>
              <td>
                <span v-if="mainContrato.percentualFatorAtendimento == 100"
                  >{{ mainContrato.percentualFatorAtendimento }}%</span
                >
                <span v-else
                  >{{ mainContrato.percentualFatorAtendimento | volume }}%</span
                >
              </td>
              <td>
                {{
                  mainContrato.calculoSumarizado
                    ? mainContrato.calculoSumarizado.volumeBruto
                    : 0 | volume
                }}
              </td>
              <td>
                {{
                  mainContrato.calculoSumarizado
                    ? mainContrato.calculoSumarizado.volumeAFaturar
                    : 0 | volume
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Redistribute from "@/pages/hot-site/newave/RedistributeVolumesNewave.vue";
import ModalBase from "../../../components/modals/ModalBase";
export default {
  props: {
    mainContrato: Object,
    demonstrativo: Object,
  },

  computed: {
    CONTRATOS_FILHOS() {
      return this.mainContrato.contratosFilhos ?? [];
    },

    TOTAL_VOLUME_SCDE() {
      if (this.CONTRATOS_FILHOS.length > 0) {
        let scdes = [];
        this.CONTRATOS_FILHOS.forEach((contrato) => {
          if (
            contrato.calculoFaturamento &&
            contrato.calculoFaturamento.apuracao
          )
            scdes.push(
              contrato.calculoFaturamento.apuracao.quantidadeMontanteScde * 1000
            );
        });
        return scdes.reduce((total, numero) => total + numero, 0) / 1000;
      }
      return 0;
    },

    TOTAL_CONSUMO_APURADO() {
      if (this.CONTRATOS_FILHOS.length > 0) {
        let apurado = [];
        this.CONTRATOS_FILHOS.forEach((contrato) => {
          if (contrato.calculoFaturamento)
            apurado.push(contrato.calculoFaturamento.volumeBruto * 1000);
        });
        return apurado.reduce((total, numero) => total + numero, 0) / 1000;
      }
      return 0;
    },

    TOTAL_VOLUME_FATURAR() {
      if (this.CONTRATOS_FILHOS.length > 0) {
        let faturar = [];
        this.CONTRATOS_FILHOS.forEach((contrato) => {
          if (contrato.calculoFaturamento)
            faturar.push(contrato.calculoFaturamento.volumeAFaturar * 1000);
        });
        return faturar.reduce((total, numero) => total + numero, 0) / 1000;
      }
      return 0;
    },
  },

  data() {
    return {
      showModalRedistribuir: false,
    };
  },

  methods: {
    close() {
      this.showModalRedistribuir = false;
    },
  },

  components: {
    Redistribute,
    ModalBase,
  },

  watch: {
    data: function (val) {
      this.sumVolumes();
    },
  },
};
</script>
<style lang="scss" scoped>
.box-units {
  width: 100%;
  margin-top: 25px;
  min-height: 150px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  background: #ffffff;
}

.units-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
}

._box_title {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #192a3e;
}

.unit-scroll {
  overflow: auto;
}

.table-unit {
  min-width: 1100px;
  width: 100%;
  border-spacing: 0;

  th {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #000;
    border-bottom: 1px solid #000;
    padding: 5px 0 5px 0;
  }

  td {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #505050;
    text-align: center;
    border-bottom: 1px solid #bbb;
  }
}

.rows-table {
  min-height: 40px !important;

  td {
    height: 40px !important;
  }
}

.cnpj {
  min-width: 140px !important;
}

.rows-total {
  border: none !important;
  background: #e8e8e8;

  td {
    border: none !important;
    font-weight: 600;
  }
}
</style>