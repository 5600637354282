<template>
  <div>
    <!--msg ainda não identificada-->
    <div v-if="1 == 2" class="box-take mb-3">
      <span>
        O <b>Take Máximo</b> deste contrato foi atingido neste faturamento,
        segue demonstrativo da medição e dos limites contratuais abaixo:
      </span>
    </div>

    <div
      v-if="
        contratoPrincipal.flexibilidade &&
        contratoPrincipal.flexibilidade.take === 'Acima' &&
        contratoPrincipal.parametrizacao.recebeCurtoPrazo &&
        !curtoPrazo.id &&
        demonstrativo.situacaoDemonstrativo.situacao === 'APROVADO'
      "
      class="box-alert-short-term-proposal"
      style="border: #ffb946 solid 1px"
    >
      <div class="box-alert-logo">
        <img :src="mountImg('/images/icon-alert-triangulo.svg')" />
      </div>
      <div class="box-alert-text">
        <b>IMPORTANTE: </b>: Preparamos uma
        <b>Proposta de Curto Prazo</b> referente ao
        <b>volume excedente</b> consumido neste mês. Você poderá recebê-la
        <span
          class="pointer"
          @click="solicitarPropostaCurtoPrazo"
          style="color: #89D99C; cursor: pointer"
          ><b>clicando aqui</b> </span
        >.
      </div>
    </div>

    <div
      v-if="
        contratoPrincipal.flexibilidade &&
        contratoPrincipal.flexibilidade.take === 'Acima' &&
        contratoPrincipal.parametrizacao.recebeCurtoPrazo &&
        demonstrativo.situacaoDemonstrativo.situacao !== 'APROVADO' &&
        demonstrativo.situacaoDemonstrativo.situacao !== 'REPROVADO'
      "
      class="box-alert-short-term-proposal"
    >
      <div class="">
        <img :src="mountImg('/images/icon-alert-triangulo.svg')" />
      </div>

      <div class="box-alert-text">
        Apresentamos abaixo o demonstrativo de faturamento do contrato
        <b>{{ contratoPrincipal.codigo }}</b
        >, cujo o volume utilizado este mês
        <b
          >ultrapassou
          <span v-if="curtoPrazo.isFatorDeAtendimentoIntegral">
            em {{ DIFERENCA | volume }} MWh
          </span>
          o limite máximo contratado</b
        >
        (Take Máximo).
        <u>O faturamento será realizado pelo take máximo contratado</u>.
        Preparamos uma <b>Proposta de Curto Prazo</b> referente ao excedente
        consumido neste mês. Você poderá acessá-la após a análise e aprovação do
        demonstrativo de faturamento abaixo:
      </div>
    </div>

    <div
      class="box-messages-short-term"
      v-if="
        demonstrativo.situacaoDemonstrativo.situacao == 'APROVADO' &&
        curtoPrazo &&
        curtoPrazo.status == 'AGUARDANDO_PRECO'
      "
    >
      <div class="box-messages-short-term-icon">
        <img :src="mountImg('/images/icon-alert-message.svg')" />
      </div>
      <div class="box-messages-short-term-message">
        <span>
          Sua solicitação foi registrada, e sua proposta será enviada em breve.
          Caso esse pedido tenha sido realizado fora do horário comercial o
          envio ocorrera no próximo dia útil.
        </span>
      </div>
    </div>

    <div
      class="box-messages-short-term"
      v-if="curtoPrazo && curtoPrazo.status == 'EM_PREENCHIMENTO'"
    >
      <div class="box-messages-short-term-icon">
        <img :src="mountImg('/images/icon-alert-message.svg')" />
      </div>
      <div class="box-messages-short-term-message">
        <span>Proposta de Curto Prazo enviada para os contatos:</span>

        <span
          style="font-size: 14px"
          v-for="(contato, indexContato) in curtoPrazo.emailsEnviados"
          :key="`indexContato${indexContato}`"
        >
          | (Email : {{ contato }})
        </span>
      </div>
    </div>

    <div
      class="box-messages-short-term"
      v-if="curtoPrazo.status === 'APROVADO'"
    >
      <div class="box-messages-short-term-icon">
        <img :src="mountImg('/images/icon-alert-triangulo.svg')" />
      </div>
      <div class="box-messages-short-term-message">
        <span>
          FOI GERADA E APROVADA UMA PROPOSTA DE CURTO PRAZO. CONSULTE O
          E-MAIL.</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import bigDecimal from "js-big-decimal";
export default {
  props: {
    contratoPrincipal: Object,
    demonstrativo: Object,
    curtoPrazo: Object,
  },
  computed: {
    DIFERENCA() {
      return this.contratoPrincipal && this.contratoPrincipal.calculoSumarizado
        ? parseFloat(
            bigDecimal.subtract(
              this.contratoPrincipal.calculoSumarizado.volumeBruto,
              this.contratoPrincipal.calculoSumarizado.volumeLiquido
            )
          )
        : 0;
    },
  },
  methods: {
    solicitarPropostaCurtoPrazo() {
      this.$emit("solicitarPropostaCurtoPrazo");
    },
  },
};
</script>
<style lang="scss" scoped>
.box-alert-short-term-proposal {
  display: flex;
  padding: 20px;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #192a3e;
  border-radius: 5px;
}
.box-alert-logo {
  display: flex;
  align-items: center;
}
.box-alert-text {
  padding-left: 15px;
}
.box-take {
  background: #fff;
  width: 100%;
  padding: 20px;
  border: solid 1px $color--primary;
  border-radius: 5px;
  margin: 10px 0 0px 0;
}
.box-messages-short-term {
  display: flex;
  background: #ffffff;
  border: 1px solid #929292;
  box-sizing: border-box;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  align-items: center;
  letter-spacing: 0.05em;
  color: #192a3e;
  padding: 5px 0 5px 0;
  margin-bottom: 17px;
}

.box-messages-short-term-icon {
  padding: 2px 13px 2px 13px;
}
</style>