var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"modall__overlay",attrs:{"data-cy":"div-sobreposicao_click-modals"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.close.apply(null, arguments)}}},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"modal__box-content",style:({
          width: _vm.width,
          minWidth: _vm.minWidth,
          maxWidth: _vm.maxWidth,
          minHeight: _vm.minHeight,
          maxHeight: _vm.maxHeight,
          background: _vm.backgroundColor,
        })},[_c('div',{staticClass:"mtx-page-header",style:({ background: _vm.backgroundColor })},[_c('div',[_vm._t("header"),(_vm.iconClose)?_c('div',{staticClass:"closeModal",attrs:{"data-cy":"div-fechar_modal_base-modals"},on:{"click":_vm.close}},[_vm._v(" × ")]):_vm._e()],2)]),_c('div',{staticClass:"modal__body"},[_vm._t("body")],2),(!_vm.footerHide)?_c('div',{staticClass:"modal__footer",style:({ background: _vm.backgroundColor })},[_vm._t("footer")],2):_vm._e()])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }