import Services from "./Services";

class ExportadorService extends Services {
  constructor(endpoint) {
    super(endpoint);
  }

  getExportador(request, endpoint) {
    this._url = endpoint
    return this.get(request, true);
  }

  exportarModeloWBC(request, completo = false) {
    this._url =
      completo === true ? "/relatorio-wbc-completo" : "/relatorio-wbc";
    return this.post(request, false, { responseType: "blob" });
  }

  exportarModeloWBCDG(request, completo = false) {
    this._url =
      completo === true ? "/relatorio-wbc-completo/dg" : "/relatorio-wbc";
    return this.post(request, false, { responseType: "blob" });
  }

  exportarModeloEncargoCCEE(request) {
    this._url = "/relatorio-wbc-completo/encargos";
    return this.post(request, false, { responseType: "blob" });
  }

  integrarBoletasSelecionadas(request) {
    this._url = "";
    return this.post(request);
  }

  integrarBoletasSelecionadasDg(request) {
    this._url = "/desconto-garantido";
    return this.post(request);
  }
}

export default new ExportadorService("/exportador");
